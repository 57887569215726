import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PrimaryLayout from 'components/layout';
import DomainsApi from 'libs/api/domains';
import { DNSConfigsView } from 'components/modules/services/create/services/email';
import { StyledModuleTitle } from 'components/titles';
import { RouteCodes } from 'common/constants';
import { Button, Col, Row, Space } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Container from 'components/container';
import ModuleWrapper from 'components/module-wrapper';

const domainApi = new DomainsApi();

const DnsConfigs = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [dnsConfig, setDnsConfig] = useState(null);
  const params = useParams();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await domainApi.get(`${params.id}/dns_to_configure`);
        setDnsConfig(data);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    })();
  }, [params]);

  console.log('dn', dnsConfig);

  return (
    <PrimaryLayout>
      <div>
        <Container>
          <ModuleWrapper
            title={
              <StyledModuleTitle style={{ marginTop: 0 }}>
                {t('DNS configs')}
              </StyledModuleTitle>
            }
            actionContent={<></>}
            headerPrefix={
              <>
                <Link to={RouteCodes.home.path}>
                  <Button
                    style={{ paddingLeft: 0 }}
                    type='link'
                    icon={<ArrowLeftOutlined />}
                  >
                    {t('To companies')}
                  </Button>
                </Link>
              </>
            }
          >
            <Row justify='center'>
              <Col>
                <DNSConfigsView
                  loading={loading}
                  hideTitle={true}
                  title={t('DNS configs')}
                  data={dnsConfig?.body}
                />
              </Col>
            </Row>
          </ModuleWrapper>
        </Container>
      </div>
    </PrimaryLayout>
  );
};

export default DnsConfigs;
