import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import useLogin from './useLogin';
import styled from 'styled-components';
import { ReactComponent as Logo } from 'assets/images/logo.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
`;

const StyledTitle = styled(Typography.Title)`
  font-size: 2rem;
  font-weight: 600;
  margin: 0 16px 0 0 !important;
  color: #ea4e29 !important;
  text-align: center;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100px;
    height: 100px;
  }
`;

const LoginPage = () => {
  const { t } = useTranslation();
  const { handleLogin, form, loading } = useLogin();

  const onFinish = async (values) => {
    await handleLogin(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Container>
      <Row>
        <Col span={24}>
          <TitleContainer>
            <StyledTitle>{t('Rimail Service')}</StyledTitle>
            <Logo />
          </TitleContainer>
        </Col>
        <Col span={24}>
          <Form
            form={form}
            layout={'vertical'}
            name='basic'
            initialValues={{
              email: '',
              password: '',
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
          >
            <Form.Item
              label='Email'
              name='email'
              rules={[
                {
                  required: true,
                  message: t('Please input your username'),
                },
              ]}
            >
              <Input style={{ width: '100%' }} size={'large'} />
            </Form.Item>

            <Form.Item
              label='Password'
              name='password'
              rules={[
                {
                  required: true,
                  message: t('Please input your password'),
                },
              ]}
            >
              <Input.Password style={{ width: '100%' }} size={'large'} />
            </Form.Item>

            <Form.Item
              wrapperCol={
                {
                  // offset: 8,
                  // span: 16,
                }
              }
            >
              <Button
                style={{ width: '100%' }}
                size={'large'}
                type='primary'
                htmlType='submit'
                loading={loading}
              >
                {t('Entra')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
export default LoginPage;
