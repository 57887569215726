import { SmallModal } from '../../modals';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 28px;
`;

const StyledBtn = styled(Button)`
  width: 100%;
  height: 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  padding: 4px 12px;
  letter-spacing: 0.1px;
`;

const BackAttentionModal = ({ onClose, open, onClickYes }) => {
  const { t } = useTranslation();

  return (
    <SmallModal
      open={open}
      closable={false}
      footer={
        <Row gutter={12}>
          <Col span={12}>
            <StyledBtn onClick={onClose}>{t('Cancel')}</StyledBtn>
          </Col>
          <Col span={12}>
            <StyledBtn onClick={onClickYes} type={'primary'}>
              {t('Go to campaings')}
            </StyledBtn>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col>
          <StyledTitle>
            {t('Do you want to back to campaings list wthout changes?')}
          </StyledTitle>
        </Col>
      </Row>
    </SmallModal>
  );
};

export default BackAttentionModal;
