import { Row } from 'antd';
import { useMemo } from 'react';
import { useServiceContext } from 'contexts/service';
import { NewsletterTypes } from 'common/constants';
import EmailService from 'components/modules/services/create/services/email';
import SmsService from 'components/modules/services/create/services/sms';

const ServicesSelect = () => {
  const { serviceData } = useServiceContext();

  const content = useMemo(() => {
    switch (serviceData.newsletter) {
      case NewsletterTypes.sms:
        return <SmsService />;
      case NewsletterTypes.email:
        return <EmailService  />;
      default:
        return <></>;
    }
  }, [serviceData]);

  return (
    <Row gutter={[20, 20]} align='middle'>
      {content}
    </Row>
  );
};

export default ServicesSelect;
