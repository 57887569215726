import { notification } from 'antd';
import moment from 'moment';

class UIHelper {
  static openNotificationWithIcon = (type, message, description) => {
    notification.config({
      duration: 10,
    });
    notification[type]({
      message,
      description,
    });
  };

  static showError = (message = 'Something went wrong') => {
    notification.error({
      message,
    });
  };

  static getDomainEmailWithoutPrefix = (email) => {
    if (!email) return email;
    const [f] = email.split('@');

    return f;
  };

  static showErrorFromAxios = (e, notificationApi) => {
    if (e?.response?.data?.errors) {
      e?.response?.data?.errors?.forEach(error => {
        (notificationApi || notification).error({
          message: error.messages?.join(''),
        });
      });
    }
  };
  static serverError = () => {
    notification.config({
      duration: 4,
    });
    notification['error']({
      message: 'Errore, per favore contatta la direzione ',
    });
  };

  static showSuccess = (message = 'Successo') => {
    notification.success({
      message,
    });
  };

  static showWarning = (message = 'Successo') => {
    notification.warning({
      message,
    });
  };

  static formatedDate = (data, format = 'DD MMMM YYYY') => {
    return moment(data).format(format);
  };

  static typeNumber = (evt) => {
    let key = evt.keyCode || evt.which;
    key = String.fromCharCode(key);
    if (key.length === 0) return;

    if (!/^[0-9,\b]+$/.test(evt.key)) {
      evt.preventDefault();
    }
  };

  static typeInteger = (evt) => {
    let key = evt.keyCode || evt.which;
    key = String.fromCharCode(key);
    if (key.length === 0) return;

    if (!/^[0-9\b]+$/.test(evt.key)) {
      evt.preventDefault();
    }
  };

  static correctionNumber = (field) => {
    try {
      const res = parseFloat(field.toString().replace(',', '.'));
      if (isNaN(res)) {
        return 0;
      }
      return Number(res);
    } catch (e) {
      return 0;
    }
  };

  static removeDot = (field) => {
    if (typeof field === 'string') {
      return field.replace('.', '');
    }
    return field;
  };

  static formatedNumber = (num) => {
    try {
      return num.replace(/[.,]00$/, '');
    } catch (e) {
      return 0;
    }
  };

  static percentage(percent, total) {
    return Number(((percent / 100) * total).toFixed(2));
  }

  static assignTire = (columns) => {
    columns?.map((i) => {
      if (!i.render) {
        i.render = (data) => {
          if (typeof data === 'number') {
            return data;
          }
          return data || '-';
        };
      } else {
        const oldRender = i.render;
        i.render = (data, row) => {
          const renderedData = oldRender(data, row);
          if (typeof renderedData === 'number') {
            return renderedData;
          }
          return renderedData || '-';
        };
      }
      return i;
    });

    return columns;
  };
}

export default UIHelper;
