import { Route, Routes as ReactRouter } from 'react-router-dom';
import { RouteCodes } from 'common/constants';

import Companies from 'pages/campaigns';
import ServicesPage from 'pages/services';
import LoginPage from 'pages/login';
import ServiceSettingsPage from 'pages/services/settings';
import ServicesTestPage from 'pages/services/test';
import { useBootstrap } from 'hooks/use-bootstrap';
import ManageCustomers from 'pages/manage-customers';
import ServiceConfigsPage from 'pages/service-configs';
import ChangePasswordPage from 'pages/change-password';
import DnsConfigs from 'pages/dns-configs';

const Routes = () => {
  useBootstrap();

  return (
    <ReactRouter>
      <Route
        path={RouteCodes.home.path}
        exact={RouteCodes.home.exact}
        element={<Companies />}
      />
      <Route
        path={RouteCodes.companies.path}
        exact={RouteCodes.companies.exact}
        element={<Companies />}
      />
      <Route
        path={RouteCodes.changePassword.path}
        exact={RouteCodes.changePassword.exact}
        element={<ChangePasswordPage />}
      />
      <Route
        path={RouteCodes.services.path}
        exact={RouteCodes.services.exact}
        element={<ServicesPage />}
      />
      <Route
        path={RouteCodes.servicesSettings.path}
        exact={RouteCodes.servicesSettings.exact}
        element={<ServiceSettingsPage />}
      />
      <Route
        path={RouteCodes.servicesSettingsTesting.path}
        exact={RouteCodes.servicesSettingsTesting.exact}
        element={<ServicesTestPage />}
      />
      <Route
        path={RouteCodes.login.path}
        exact={RouteCodes.login.exact}
        element={<LoginPage />}
      />
      <Route
        path={RouteCodes.servicesConfigs.path}
        exact={RouteCodes.servicesConfigs.exact}
        element={<ServiceConfigsPage />}
      />
      <Route
        path={RouteCodes.dnsConfig.path}
        exact={RouteCodes.dnsConfig.exact}
        element={<DnsConfigs />}
      />
      <Route
        path={RouteCodes.manageCustomers.path}
        exact={RouteCodes.manageCustomers.exact}
        element={<ManageCustomers />}
      />
    </ReactRouter>
  );
};

export default Routes;
