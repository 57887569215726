import Container from 'components/container';
import { Button, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { StyledModuleTitle } from 'components/titles';
import { SectionItem } from 'components/modules/services/index';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useServiceContext } from 'contexts/service';
import { ServicesDataKeys } from 'common/constants';
import Setting from 'components/modules/services/create/settings';

const ServiceSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { serviceData, onChangeServiceData } = useServiceContext();

  const onBack = useCallback(
    (e) => {
      e.preventDefault();
      navigate(-1);
      onChangeServiceData(ServicesDataKeys.domainEmail, undefined);
    },
    // eslint-disable-next-line
    [navigate],
  );

  const title = useMemo(() => {
    let text = t('Settings of mail method');
    if (serviceData[ServicesDataKeys.newsletter] === 'email') {
      text = t('Settings of mail method');
    }

    return text;
  }, [t, serviceData]);

  return (
    <div>
      <Container>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <SectionItem
              rowProps={{
                align: 'middle',
                justify: 'start',
                style: { marginBottom: 20 },
              }}
              prefix={
                <>
                  <a href='/' onClick={onBack}>
                    <Button
                      style={{ paddingLeft: 0 }}
                      type='link'
                      icon={<ArrowLeftOutlined />}
                    >
                      {t('To main servise settings')}
                    </Button>
                  </a>
                </>
              }
            >
              <StyledModuleTitle style={{ margin: 0 }}>
                {title}
              </StyledModuleTitle>
            </SectionItem>
          </Col>
          <Col span={24}>
            <Row gutter={[50, 50]}>
              <Col span={24}>
                <SectionItem
                  rowProps={{ align: 'middle', justify: 'start' }}
                  prefix={<></>}
                >
                  <Setting variant={serviceData?.newsletter} />
                </SectionItem>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServiceSettings;
