import { CustomDivider, StyledStep } from 'components/custom-step';
import { SectionItem } from 'components/modules/services/index';
// import Newsletter from 'components/modules/services/create/newsletter';
import { Col } from 'antd';
import { useServiceContext } from 'contexts/service';
import { useMemo } from 'react';
import ServicesSelect from 'components/modules/services/create/services';

const SecondStep = ({ customer }) => {
  const { completedSteps } = useServiceContext();

  const showDivider = useMemo(() => {
    return !!completedSteps[2];
  }, [completedSteps]);

  return (
    <>
      <Col span={24}>
        <SectionItem
          rowProps={{ align: 'top', justify: 'start' }}
          prefix={
            <>
              <StyledStep active={showDivider}>2</StyledStep>
              {showDivider && <CustomDivider />}
            </>
          }
        >
          <ServicesSelect />
          {/*<Newsletter />*/}
        </SectionItem>
      </Col>
    </>
  );
};

export default SecondStep;
