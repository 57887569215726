import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { RouteCodes } from 'common/constants';

export const useBootstrap = () => {
  const navigate = useNavigate();
  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.response?.status === 422) {
          // UIHelper.showRequestErrorToast();
        }
        if (error?.response?.status === 404) {
          // UIHelper.showNotFoundErrorToast();
        }
        if (error?.response?.status === 413) {
          // UIHelper.showServerConfigError();
        }
        if (error?.response?.status === 401) {

          navigate(RouteCodes.login.path);
        }
        return Promise.reject(error);
      },
    );
    //eslint-disable-next-line
  }, []);
};
