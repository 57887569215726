import PrimaryLayout from 'components/layout';
import Container from 'components/container';
import { StyledModuleTitle, StyledTableHeader, StyledTableText } from 'components/titles';
import ModuleWrapper from 'components/module-wrapper';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Divider, Row, Table } from 'antd';
import DomainEmailsApi from 'libs/api/domain_emails';
import { Link, useParams } from 'react-router-dom';
import { RouteCodes } from 'common/constants';
import { ArrowLeftOutlined } from '@ant-design/icons';

const domainEmailsApi = new DomainEmailsApi();

const ServiceConfigsPage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [domainConfig, setDomainConfig] = useState([]);
  const params = useParams();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await domainEmailsApi.get(`${params.id}/show_config`);
        setDomainConfig([data.config]);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    })();
  }, [params]);

  const columns = useMemo(() => {
    return [
      {
        width: 180,
        title: <StyledTableHeader>{t('Host')}</StyledTableHeader>,
        dataIndex: 'host',
        render: (data) => <StyledTableText>{data}</StyledTableText>,
      },
      {
        width: 100,
        title: <StyledTableHeader>{t('Port')}</StyledTableHeader>,
        dataIndex: 'port',
        render: (data) => <StyledTableText>{data}</StyledTableText>,
      },
      {
        width: 210,
        title: <StyledTableHeader>{t('User name')}</StyledTableHeader>,
        dataIndex: 'user_name',
        render: (data) => <StyledTableText>{data}</StyledTableText>,
      },
      {
        width: 180,
        title: <StyledTableHeader>{t('Password')}</StyledTableHeader>,
        dataIndex: 'password',
        render: (data) => <StyledTableText>{data}</StyledTableText>,
      },
      {
        width: 210,
        title: <StyledTableHeader>{t('Allowed addresses')}</StyledTableHeader>,
        dataIndex: 'allowed_addresses',
        render: (data) => (
          <StyledTableText>{data ? data.join(', ') : ''}</StyledTableText>
        ),
      },
    ];
  }, [t]);

  console.log('domainConfig', domainConfig);

  return (
    <PrimaryLayout>
      <div>
        <Container>
          <ModuleWrapper
            title={<StyledModuleTitle style={{ marginTop: 0 }}>{t('Email config')}</StyledModuleTitle>}
            actionContent={
              <></>
            }
            headerPrefix={
              <>
                <Link to={RouteCodes.home.path}>
                  <Button
                    style={{ paddingLeft: 0 }}
                    type='link'
                    icon={<ArrowLeftOutlined />}
                  >
                    {t('To companies')}
                  </Button>
                </Link>
              </>
            }
          >

            <Row gutter={[0, 0]}>
              <Col span={24}>
                <Table
                  loading={loading}
                  pagination={false}
                  bordered={true}
                  columns={columns}
                  dataSource={domainConfig}
                />
              </Col>
              <Col span={24}>
                <Divider />
              </Col>
              <Col span={24}>
                <Link to={RouteCodes.home.path}>
                  <Button type='default'>
                    {t('Go home page')}
                  </Button>
                </Link>
              </Col>
            </Row>
          </ModuleWrapper>
        </Container>
      </div>
    </PrimaryLayout>
  );
};

export default ServiceConfigsPage;
