import { Modal } from 'antd';
import styled from 'styled-components';

const StyledSmallModal = styled(Modal)`
  .ant-modal-content {
    padding: 24px 16px;
    max-width: 344px;
  }
`;

export const SmallModal = ({ open, onClose, children, ...rest }) => {
  return (
    <StyledSmallModal width={344} open={open} onCancel={onClose} {...rest}>
      {children}
    </StyledSmallModal>
  );
};
