import PrimaryLayout from 'components/layout';
import ServicesTest from 'components/modules/services/test';

const ServicesTestPage = () => {
  return (
    <PrimaryLayout>
      <ServicesTest />
    </PrimaryLayout>
  );
};

export default ServicesTestPage;
