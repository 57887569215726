import styled from 'styled-components';
import { Layout } from 'antd';

export const StyledHeader = styled(Layout.Header)`
  background: #FFFFFF !important;
  box-shadow: 0px 1px 0px #D3D9DE;
  max-height: 48px;
  height: 48px !important;
  line-height: 48px !important;
`;
