import PrimaryLayout from 'components/layout';
import Container from 'components/container';
import ModuleWrapper from 'components/module-wrapper';
import { Link } from 'react-router-dom';
import { RouteCodes } from 'common/constants';
import { Button, Col, Form, Input, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { StyledModuleTitle } from 'components/titles';
import { useState } from 'react';
import UIHelper from 'services/helpers/UIHelper';
import ErrorHelper from 'services/helpers/ErrorHelper';
import AuthApi from 'libs/api/auth';

const authApi = new AuthApi();

const ChangePasswordPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [inProcess, setInProcess] = useState(false);

  const onFinish = async (values) => {
    try {
      setInProcess(true);
      await authApi.updatePassword({
        data: values,
      });
      UIHelper.showSuccess(t('Password successfully updated'));
      // TODO maybe need remove values
      // form.resetFields(['current_password']);
    } catch (e) {
      UIHelper.showErrorFromAxios(e);
      form.setFields(
        ErrorHelper.getErrorsForForm(e?.response?.data?.errors || {}, {}),
      );
    } finally {
      setInProcess(false);
    }
    console.log('Success:', values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <PrimaryLayout>
      <div>
        <Container>
          <ModuleWrapper
            title={
              <StyledModuleTitle style={{ marginTop: 0 }}>
                {t('Change password')}
              </StyledModuleTitle>
            }
            headerPrefix={
              <>
                <Link to={RouteCodes.home.path}>
                  <Button
                    style={{ paddingLeft: 0 }}
                    type='link'
                    icon={<ArrowLeftOutlined />}
                  >
                    {t('To companies')}
                  </Button>
                </Link>
              </>
            }
          >
            <Row justify='center'>
              <Col>
                <Form
                  form={form}
                  name='basic'
                  labelCol={{
                    // span: 8,
                  }}
                  layout='vertical'
                  wrapperCol={{
                    // span: 16,
                  }}
                  style={{
                    maxWidth: 600,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete='off'
                >
                  <Form.Item
                    label='Current password'
                    name='current_password'
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password style={{ width: 300 }} />
                  </Form.Item>

                  <Form.Item
                    label='New password'
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: 'Please input your new password!',
                      },
                    ]}
                  >
                    <Input.Password style={{ width: 300 }} />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button loading={inProcess} type='primary' htmlType='submit'>
                      {t('Update')}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </ModuleWrapper>
        </Container>
      </div>
    </PrimaryLayout>
  );
};

export default ChangePasswordPage;
