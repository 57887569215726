import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button, Col, Divider, Form, Input, Row, Select } from 'antd';
import ShowServerDetails from 'components/modules/services/create/settings/ShowServerDetails';
import { useTranslation } from 'react-i18next';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import DataHelper from 'services/helpers/DataHelper';

const IPItemWrapper = styled.div`
  position: relative;
`;

const DeleteButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 300px;
`;

const IPItem = ({ id, subnets = [], onRemove, item, showRemove }) => {
  const { t } = useTranslation();

  const onDelete = useCallback(() => {
    onRemove(item);
  }, [onRemove, item]);

  return (
    <IPItemWrapper>
      <Form.Item
        label={'IP'}
        name={`ips_with_prefix:${id}`}
        rules={[
          {
            required: true,
            message: t('IPs with prefix is required'),
          },
        ]}
        style={{ marginBottom: 24 }}
      >
        <Input size='large' placeholder='IP' />
      </Form.Item>
      <Form.Item
        label={'Subnet'}
        name={`subnet:${id}`}
        rules={[
          {
            required: true,
            message: t('subnet is required'),

          },
        ]}
        style={{ marginBottom: 24 }}
      >
        <Select size='large' placeholder='subnet'>
          <Select.Option disabled={true} value={''}>
            {t('subnet')}
          </Select.Option>
          {subnets.map((i) => (
            <Select.Option key={i.value} value={i.value}>
              {i.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <ShowServerDetails
      // ips={`${ipsWatch}${subnetWatch ? `/${subnetWatch}` : ''}`}
      />
      {showRemove && (
        <DeleteButtonWrapper>
          <Button
            danger={true}
            size='large'
            type='primary'
            icon={<DeleteOutlined />}
            style={{ marginLeft: 20 }}
            onClick={onDelete}
          />
        </DeleteButtonWrapper>
      )}
    </IPItemWrapper>
  );
};

const SmtpIPs = ({ ips_with_prefix, form, dataByArray }) => {
  console.log('ips_with_prefix', ips_with_prefix);
  const [items, setItems] = useState([
    {
      id: uuidv4(),
      ip: '',
      subnet: '',
    },
  ]);

  useEffect(() => {
    if (ips_with_prefix) {
      const data = DataHelper.parseStringIPsAndSubnet(ips_with_prefix, dataByArray);
      const formDataForSet = {};
      data.forEach(item => {
        formDataForSet[`ips_with_prefix:${item.id}`] = item.ip;
        formDataForSet[`subnet:${item.id}`] = item.subnet;
      });
      if (form) {
        form.setFieldsValue(formDataForSet);
      }
      setItems(data);
    }
  }, [form, ips_with_prefix, dataByArray]);

  const onRemove = useCallback((item) => {
    setItems((s) => s.filter((i) => i.id !== item.id));
  }, []);

  const onAdd = useCallback(() => {
    setItems((s) => [
      ...s,
      {
        id: uuidv4(),
        ip: '',
        subnet: '',
      },
    ]);
  }, []);

  const subnets = useMemo(() => {
    const arr = [];

    for (let i = 24; i <= 32; i++) {
      arr.push({
        value: i,
        label: i,
      });
    }

    return arr;
  }, []);

  return (
    <>
      {items.map((i) => (
        <IPItem
          key={i.id}
          ip={i.ip}
          subnets={subnets}
          subnet={i.subnet}
          id={i.id}
          item={i}
          onRemove={onRemove}
          showRemove={items.length > 1}
        />
      ))}
      <Form.Item style={{ margin: 0 }}>
        <Row justify='end' style={{ maxWidth: 300 }}>
          <Col>
            <Button
              size='middle'
              type='primary'
              icon={<PlusOutlined />}
              onClick={onAdd}
            >
              Add
            </Button>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Row justify='end' style={{ maxWidth: 300 }}>
          <Col span={24}>
            <Divider />
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};

export default SmtpIPs;
