import Api from 'libs/api/index';

class TransactionalsApi extends Api {
  constructor() {
    super('transactionals');
  }

  sendTest = (params) => {
    return this.buildQuery('post', params, 'send_test');
  };
}

export default TransactionalsApi;
