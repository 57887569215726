import Api from 'libs/api/index';

class CompaniesApi extends Api {
  constructor() {
    super('companies');
  }

  addUser = (id, params) => {
    return this.buildQuery('post', params, `${id}/add_user`);
  };

  unlinkUser = (id, params) => {
    return this.buildQuery('post', params, `${id}/unlink_user`);
  };
}

export default CompaniesApi;
