import { CustomDivider, StyledStep } from 'components/custom-step';
import { SectionItem } from 'components/modules/services/index';
import { Col } from 'antd';
import { useMemo } from 'react';
import { useServiceContext } from 'contexts/service';
import Setting from 'components/modules/services/create/settings';
import { NewsletterTypes } from 'common/constants';

const ThirdStep = () => {
  const { completedSteps } = useServiceContext();

  const showDivider = useMemo(() => {
    return !!completedSteps[3];
  }, [completedSteps]);
  return (
    <>
      <Col span={24}>
        <SectionItem
          rowProps={{ align: 'middle', justify: 'start' }}
          prefix={
            <>
              <StyledStep active={showDivider}>3</StyledStep>
              <CustomDivider variant='top' />
            </>
          }
        >
          <Setting variant={NewsletterTypes.sms} />
          {/*<ServicesSelect />*/}
        </SectionItem>
      </Col>
    </>
  );
};

export default ThirdStep;
