import AuthApi from 'libs/api/auth';
import AuthService from 'services/Auth';
import { useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const api = new AuthApi();

const useLogin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const handleLogin = async (values) => {
    setLoading(true);
    try {
      const { data } = await api.signIn({ data: values });
      AuthService.setToken(data.token);
      navigate('/');
    } catch (e) {
      console.log(e);
      form.setFields([
        {
          name: 'email',
          errors: [t('Invalid email or password')],
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  return {
    handleLogin,
    form,
    loading,
  };
};

export default useLogin;
