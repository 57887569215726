import { Col, Form, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useServiceContext } from 'contexts/service';
import { MainButton } from 'components/buttons/main';
import { NewsletterTypes, ServicesDataKeys } from 'common/constants';

const Newsletter = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const {
    serviceData: { newsletter },
    completedSteps,
    onChangeServiceData,
    onChangeCompletedStep,
  } = useServiceContext();

  const newsletterTypes = useMemo(() => {
    return [
      {
        id: '',
        disabled: true,
        title: t('Chose type')
      },
      {
        id: NewsletterTypes.email,
        title: t('Email'),
      },
      {
        id: NewsletterTypes.sms,
        title: t('SMS'),
      },
    ];
  }, [t]);

  const onChangeCustomer = useCallback(
    (value) => {
      onChangeServiceData(ServicesDataKeys.newsletter, value);
      onChangeServiceData(ServicesDataKeys.userService, '');
      onChangeServiceData(ServicesDataKeys.domain, '');
      onChangeCompletedStep(2, false);
    },
    [onChangeServiceData, onChangeCompletedStep],
  );

  const onNext = useCallback(() => {
    onChangeCompletedStep(1, true);
  }, [onChangeCompletedStep]);

  const showNextButton = useMemo(() => {
    return !completedSteps[1] && !!newsletter;
  }, [completedSteps, newsletter]);

  const onChangeSelect = useCallback((value) => {
    onChangeCustomer(value);
  }, [onChangeCustomer]);

  const disabled = useMemo(() => {
    return !!completedSteps[3];
  }, [completedSteps]);

  return (
    <Form
      layout='vertical'
      form={form}
    >
      <Form.Item
        style={{ marginBottom: 0 }}
        name='newsletter'
        // label={<span style={{ opacity: 0 }}>l</span>}
      >
        <Select
          disabled={disabled}
          onChange={onChangeSelect}
          placeholder={t('Chose type of newsletter')}
          size='large'
          value={newsletter}
        >
          {newsletterTypes.map(i => (
            <Select.Option disabled={i.disabled} key={i.id}>{i.title}</Select.Option>
          ))}
        </Select>
        {!!showNextButton && (
          <Row justify='end' style={{ marginTop: 20 }}>
            <Col>
              <MainButton type='default' onClick={onNext}>
                {t('Next')}
              </MainButton>
            </Col>
          </Row>
        )}
      </Form.Item>
    </Form>
  );
};


export default Newsletter;
