import styled, { css } from 'styled-components';
import { Layout } from 'antd';

export const StyledFooter = styled(Layout.Footer)`
  background: #F2F3F5;
  height: 48px;
  padding: 10px 0 !important;
`;

export const StyledLinks = styled.a`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  text-decoration-line: underline;

  color: #000000;
`;

export const StyledMapLink = styled.a`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  margin: 0;
  color: #000000;
  
  ${({ link = true }) => link && css`
    text-decoration-line: underline;
  `}
`;

export const PrivacyText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: right;

  color: #6D7885;
`;
