import { StyledContent, StyledLayout } from 'components/layout/styled';
import PrimaryHeader from 'components/layout/header';
import PrimaryFooter from 'components/layout/footer';

const PrimaryLayout = ({ children }) => {
  return (
    <StyledLayout>
      <PrimaryHeader />
      <StyledContent>{children}</StyledContent>
      <PrimaryFooter />
    </StyledLayout>
  );
};

export default PrimaryLayout;
