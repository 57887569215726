import { Col, Row } from 'antd';

const ModuleWrapper = ({ prefixContent, headerPrefix, actionContent, title, children }) => {
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Row gutter={[30, 30]}>
            <Col xxl={5} xl={5} lg={5}>
              {headerPrefix}
            </Col>
            <Col xxl={14} xl={14} lg={14}>
              {title}
            </Col>
            <Col xxl={5} xl={5} lg={5} />
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[20, 20]} justify='end'>
            {/*<Col xxl={5} xl={5} lg={5}>*/}
            {/*  {prefixContent}*/}
            {/*</Col>*/}
            <Col>
              {actionContent}
            </Col>
            <Col xxl={24} xl={24} lg={24}>
              {children}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ModuleWrapper;
