import { CustomDivider, StyledStep } from 'components/custom-step';
import { SectionItem } from 'components/modules/services/index';
import { Col } from 'antd';
import { useServiceContext } from 'contexts/service';
import { useMemo } from 'react';
import Newsletter from 'components/modules/services/create/newsletter';

const FirstStep = ({ customer }) => {
  const { completedSteps } = useServiceContext();

  const showDivider = useMemo(() => {
    return !!completedSteps[1];
  }, [completedSteps]);

  return (
    <Col span={24}>
      <SectionItem
        rowProps={{ align: 'top', justify: 'start' }}
        prefix={
          <>
            <StyledStep active={true}>1</StyledStep>
            {showDivider && <CustomDivider />}
          </>
        }
      >
        <Newsletter />
      </SectionItem>
    </Col>
  );
};

export default FirstStep;
