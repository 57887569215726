export const customerAdapter = (data) => {
  if (!data) {
    return [];
  }

  const newArr = [];

  const prepareData = (i) => ({
    ...i,
    title: i.username || i.email || i.name || i.title,
    label: i.username || i.email || i.name || i.title,
  });

  if (Array.isArray(data)) {
    data.forEach((i) => {
      newArr.push(prepareData(i));
    });

    return newArr;
  }

  return prepareData(data);
};
