import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Table } from 'antd';
import PrimaryLayout from 'components/layout';
import Container from 'components/container';
import { StyledModuleTitle } from 'components/titles';
import ModuleWrapper from 'components/module-wrapper';
import { useCompanies } from 'pages/campaigns/use-companies';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { CompanyActionTypes, ServiceActionTypes } from 'common/constants';
import {
  AddOrEditCompanyContent,
  DeleteCustomerContent,
  DeleteServiceContent,
  DisableCustomerContent,
  DisableServiceContent,
  EditEmailSettings,
  SMSConfigModal,
} from 'components/modal-contents';
import { SmallModal } from 'components/modals';
import { useServiceContext } from 'contexts/service';
import EditSMSMethodModal from 'components/myModals/editSMSMethodModal';

const CampaignsPage = () => {
  const { t } = useTranslation();

  const {
    columns,
    loading,
    data,
    // pagination,
    onCloseModal,
    showModal,
    showSMSModal,
    showServiceModal,
    selectedElement,
    onDelete,
    onDisable,
    onDisableService,
    inProcess,
    onClickCreate,
    onCreate,
    selectedService,
    onCloseServiceModal,
    onDeleteService,
    showEmailsModal,
    fetchData,
    smsConfigModal,
    onCloseSmsModal,
  } = useCompanies();

  const { resetState } = useServiceContext();

  useEffect(() => {
    resetState();
    //eslint-disable-next-line
  }, []);

  const servicesContent = useMemo(() => {
    switch (selectedService?.action) {
      case ServiceActionTypes.delete:
        return (
          <DeleteServiceContent
            onDelete={onDeleteService}
            item={selectedService?.item}
            onClose={onCloseServiceModal}
            inProcess={inProcess}
          />
        );
      case ServiceActionTypes.disable:
        return (
          <DisableServiceContent
            onDisable={onDisableService}
            item={selectedService?.item}
            onClose={onCloseServiceModal}
            inProcess={inProcess}
          />
        );
      case ServiceActionTypes.edit:
        return (
          <EditEmailSettings
            onDisable={onDisableService}
            item={selectedService?.item}
            onClose={onCloseServiceModal}
            inProcess={inProcess}
          />
        );
      default:
        return <></>;
    }
  }, [
    selectedService,
    onCloseServiceModal,
    onDeleteService,
    inProcess,
    onDisableService,
  ]);

  const modalContent = useMemo(() => {
    switch (selectedElement?.action) {
      case CompanyActionTypes.delete:
        return (
          <DeleteCustomerContent
            onClose={onCloseModal}
            item={selectedElement?.item}
            onDelete={onDelete}
            inProcess={inProcess}
          />
        );
      case CompanyActionTypes.disable:
        return (
          <DisableCustomerContent
            onClose={onCloseModal}
            item={selectedElement?.item}
            onDisable={onDisable}
            inProcess={inProcess}
          />
        );
      case CompanyActionTypes.create:
      case CompanyActionTypes.edit:
        return (
          <AddOrEditCompanyContent
            onClose={onCloseModal}
            item={selectedElement?.item}
            onDisable={onDisable}
            inProcess={inProcess}
            onFinish={onCreate}
          />
        );
      default:
        return <></>;
    }
  }, [selectedElement, onCloseModal, onDelete, inProcess, onDisable, onCreate]);

  return (
    <>
      <PrimaryLayout>
        <div>
          <Container>
            <ModuleWrapper
              title={
                <StyledModuleTitle style={{ marginTop: 0 }}>
                  {t('My companies')}
                </StyledModuleTitle>
              }
              actionContent={
                <Row justify='end'>
                  <Col>
                    <Button
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={onClickCreate}
                    >
                      {t('Add company')}
                    </Button>
                  </Col>
                </Row>
              }
            >
              <Table
                rowClassName={(record, index) =>
                  record.status ? 'table-row-light' : 'table-row-dark'
                }
                rowKey='id'
                bordered={true}
                loading={loading}
                dataSource={data}
                columns={columns}
                pagination={false}
                // pagination={pagination}
              />
            </ModuleWrapper>
          </Container>
        </div>
        {!!showModal && (
          <SmallModal
            centered={true}
            closable={false}
            open={showModal}
            onClose={() => onCloseModal('', false)}
            footer={false}
          >
            {modalContent}
          </SmallModal>
        )}
        {!!showServiceModal && (
          <SmallModal
            centered={true}
            closable={false}
            open={showServiceModal}
            onClose={onCloseServiceModal}
            footer={false}
          >
            {servicesContent}
          </SmallModal>
        )}
        {!!showSMSModal && (
          <EditSMSMethodModal
            afterEdit={fetchData}
            onClose={onCloseServiceModal}
            open={showSMSModal}
            item={selectedService?.item}
          />
        )}
        {!!showEmailsModal && (
          <Modal
            centered={true}
            closable={false}
            open={showEmailsModal}
            onClose={onCloseServiceModal}
            footer={false}
          >
            <EditEmailSettings
              afterEdit={fetchData}
              item={selectedService.item}
              onClose={onCloseServiceModal}
            />
          </Modal>
        )}
        {smsConfigModal?.item && (
          <Modal
            footer={false}
            centered={true}
            closable={true}
            open={true}
            onCancel={onCloseSmsModal}
          >
            <SMSConfigModal item={smsConfigModal?.item} />
          </Modal>
        )}
      </PrimaryLayout>
    </>
  );
};

export default CampaignsPage;
