import PrimaryLayout from 'components/layout';
import ServicesSettings from 'components/modules/services/settings';

const ServiceSettingsPage = () => {
  return (
    <PrimaryLayout>
      <ServicesSettings />
    </PrimaryLayout>
  );
};

export default ServiceSettingsPage;
