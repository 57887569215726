import styled from 'styled-components';
import { Typography } from 'antd';

export const StyledModuleTitle = styled(Typography.Title)`
  font-style: normal;
  font-weight: 400 !important;
  font-size: 32px !important;
  line-height: 64px;
  letter-spacing: 0.2px;

  color: #2C2D2E !important;
`;

export const StyledTableHeader =  styled(Typography.Paragraph)`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130%;
  margin: 0 !important;
  text-align: center;
  color: #FFFFFF;
`;

export const StyledTableText = styled(Typography.Paragraph)`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin: 0 !important;
  line-height: 130%;
  padding-right: 16px;
  padding-left: 16px;
  color: #000000;
`;

export const StyledModalTitle = styled(Typography.Title)`
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 24px;
  /* identical to box height, or 120% */
  text-align: center;
  color: #000000;
`;

export const StyledModalDescription = styled(Typography.Paragraph)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  text-align: center;
  letter-spacing: 0.2px;

  color: #6D7885;
`;
