import styled from 'styled-components';

const StyledWrapper = styled.div`
  max-width: 1080px;
  margin: auto;
  height: 100%;
`;

const Container = ({ children }) => {
  return (
    <StyledWrapper>
      {children}
    </StyledWrapper>
  );
};

export default Container;
