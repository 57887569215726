import { SmallModal } from '../../modals';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import UserServices from 'libs/api/user_services';
import { useParams } from 'react-router-dom';
import UIHelper from 'services/helpers/UIHelper';
import ErrorHelper from 'services/helpers/ErrorHelper';
import { ServicesDataKeys } from 'common/constants';
import { useServiceContext } from 'contexts/service';
import FormHelper from 'services/helpers/FormHelper';

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 28px;
`;

const StyledBtn = styled(Button)`
  width: 100%;
  height: 44px;
  font-weight: 500;
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.4;
          color: #fff !important;
          background: #2d70be !important;
        `
      : ''}
`;

const api = new UserServices();

const NewSMSBackupMethodModal = ({ onClose, open, title }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const params = useParams();
  const [loading, setLoading] = useState(false);

  const {
    serviceData: { userServiceReservedAllData },
    onChangeServiceData,
  } = useServiceContext();

  const onFinish = useCallback(
    async (values) => {
      try {
        setLoading(true);
        const { data } = await api.create({
          data: {
            ...values,
            variant: 'reserved',
            company_id: params.companyId,
          },
        });

        onChangeServiceData(
          ServicesDataKeys.userServiceReserved,
          data.user_service.id,
        );
        onChangeServiceData(
          ServicesDataKeys.userServiceReservedAllData,
          data.user_service || userServiceReservedAllData,
        );
        UIHelper.showSuccess('SMS service successfully created');
        onClose();
      } catch (e) {
        UIHelper.showErrorFromAxios(e);
        form.setFields(
          ErrorHelper.getErrorsForForm(e?.response?.data?.errors || {}, {}),
        );
      } finally {
        setLoading(false);
      }
    },
    [
      params,
      form,
      onClose,
      userServiceReservedAllData,
      onChangeServiceData,
    ],
  );

  const service_type = Form.useWatch('service_type', form);

  const formContent = useMemo(() => {
    if (service_type === 'telecomitalia') {
      return (
        <>
          <Form.Item name='alias'>
            <Input allowClear size={'large'} placeholder={t('alias')} />
          </Form.Item>
          <Form.Item name='service_auth_url'>
            <Input allowClear size={'large'} placeholder={t('api auth url')} />
          </Form.Item>
          <Form.Item name='username'>
            <Input allowClear size={'large'} placeholder={t('username')} />
          </Form.Item>
        </>
      );
    }

    return (
      <>
        <Form.Item name='api_key'>
          <Input allowClear size={'large'} placeholder={t('api key')} />
        </Form.Item>
      </>
    );
  }, [t, service_type]);

  useEffect(() => {
    const data = {
      service_url: 'https://sms.world-text.com/v2.0',
      service_auth_url: '',
    };
    if (service_type === 'telecomitalia') {
      data.service_url =
        'https://channel-ssc-api.services.smb.telecomitalia.it';
      data.service_auth_url =
        'https://auth-jwt-ssc-api.services.smb.telecomitalia.it';
    }
    if (service_type === 'world_text') {
      data.service_url = 'https://sms.world-text.com/v2.0';
    }

    form.setFields([
      {
        name: 'service_url',
        value: data.service_url,
      },
      {
        name: 'service_auth_url',
        value: data.service_auth_url,
      },
    ]);
  }, [service_type, form]);

  return (
    <SmallModal
      open={open}
      closable={false}
      footer={
        <Row gutter={12}>
          <Col span={12}>
            <StyledBtn onClick={onClose}>{t('Close')}</StyledBtn>
          </Col>
          <Col span={12}>
            <StyledBtn
              loading={loading}
              onClick={() => form.submit()}
              type={'primary'}
            >
              {t('Set')}
            </StyledBtn>
          </Col>
        </Row>
      }
    >
      <Form
        form={form}
        name='basic'
        initialValues={{
          service_url: '',
          title: title || '',
          api_secret: '',
          service_type: 'world_text', // ENUM [telecomitalia, world_text]
          variant: 'reserved', // ENUM [primary, reserved], reserved meaning it can be reserved for another service

          // api_key: '',
          service_auth_url: '',
          username: '',
          alias: '',
        }}
        onFinish={onFinish}
        autoComplete='off'
        layout={'vertical'}
        onFieldsChange={FormHelper.resetFormsFieldErrors(form, [
          'api_key',
          'service_auth_url',
          'api_secret',
        ])}
      >
        <Row>
          <Col span={24}>
            <StyledTitle>{t('New backup SMS method')}</StyledTitle>
          </Col>
          <Col span={24}>
            <Form.Item name='service_type'>
              <Select size='large' style={{ maxWidth: 'unset' }}>
                <Select.Option value='telecomitalia'>
                  Telecomitalia
                </Select.Option>
                <Select.Option value='world_text'>World text</Select.Option>
              </Select>
            </Form.Item>
            {/*<Form.Item name='variant'>*/}
            {/*  <Select*/}
            {/*    size='large'*/}
            {/*    style={{ maxWidth: 'unset' }}*/}
            {/*    placeholder='Variant'*/}
            {/*  >*/}
            {/*    <Select.Option value='primary'>Primary</Select.Option>*/}
            {/*    <Select.Option value='reserved'>Backup</Select.Option>*/}
            {/*  </Select>*/}
            {/*</Form.Item>*/}
            <Form.Item name='title'>
              <Input allowClear size={'large'} placeholder={t('title')} />
            </Form.Item>
            <Form.Item
              name='service_url'
              rules={[
                {
                  required: true,
                  message: t('Please input service url'),
                },
              ]}
            >
              <Input allowClear size={'large'} placeholder={t('service url')} />
            </Form.Item>
            {formContent}
            <Form.Item name='api_secret'>
              <Input
                allowClear
                size={'large'}
                placeholder={
                  service_type === 'telecomitalia'
                    ? t('Password')
                    : t('Account ID')
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </SmallModal>
  );
};

export default NewSMSBackupMethodModal;
