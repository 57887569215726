import Container from 'components/container';
import { Button, Col, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { StyledModuleTitle } from 'components/titles';
import { useServiceContext } from 'contexts/service';
import { useTranslation } from 'react-i18next';
import FirstStep from 'components/modules/services/steps/first';
import SecondStep from 'components/modules/services/steps/second';
import ThirdStep from 'components/modules/services/steps/third';
import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import BackAttentionModal from 'components/myModals/backAttentionModal';
// import FourthStep from 'components/modules/services/steps/fourth';

export const SectionItem = ({ prefix, children, rowProps = {} }) => {
  return (
    <>
      <Row {...rowProps}>
        <Col xxl={7} xl={7} lg={5}>
          {prefix}
        </Col>
        <Col>{children}</Col>
      </Row>
    </>
  );
};

const Services = () => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { completedSteps, resetState } = useServiceContext();

  const onClickNavigate = useCallback(() => {
    navigate('/');
    resetState();
  }, [navigate, resetState]);
  return (
    <div>
      <Container>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <SectionItem
              rowProps={{
                align: 'middle',
                justify: 'start',
                style: { marginBottom: 20 },
              }}
              prefix={
                <>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setShowModal(true);
                    }}
                  >
                    <Button
                      style={{ paddingLeft: 0 }}
                      type='link'
                      icon={<ArrowLeftOutlined />}
                    >
                      {t('To campaings')}
                    </Button>
                  </a>
                </>
              }
            >
              <StyledModuleTitle style={{ margin: 0 }}>
                {t('New service')}
              </StyledModuleTitle>
            </SectionItem>
          </Col>
          <Col span={24}>
            <Row gutter={[50, 50]}>
              <FirstStep />
              {!!completedSteps['1'] && <SecondStep />}
              {!!completedSteps['2'] && <ThirdStep />}
              {/*{!!completedSteps['3'] && <FourthStep />}*/}
            </Row>
          </Col>
        </Row>
      </Container>
      {showModal && (
        <BackAttentionModal
          onClose={() => setShowModal(false)}
          open={showModal}
          onClickYes={onClickNavigate}
        />
      )}
    </div>
  );
};

export default Services;
