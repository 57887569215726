import PrimaryLayout from 'components/layout';
import Container from 'components/container';
import { StyledModuleTitle } from 'components/titles';
import { Button, Col, Row, Modal, Table } from 'antd';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ModuleWrapper from 'components/module-wrapper';
// import { useCustomers } from 'pages/manage-customers/use-customers';
import { Route, useNavigate, Routes, useParams, Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import AddOrEditCustomerModal from 'components/myModals/addOrEditCustomer';
import { RouteCodes } from 'common/constants';
import { useCustomers } from 'hooks/use-customers';
import CompaniesApi from 'libs/api/companies';

const companyApi = new CompaniesApi();

const ManageCustomers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [company, setCompany] = useState(null);
  const {
    data,
    loading,
    columns,
    fetchData,
    onClickCreate,
    usersAllData,
    fetchUsersAllData,
  } = useCustomers(true, params.companyId);

  const onCloseModal = useCallback(() => {
    navigate(
      RouteCodes.manageCustomers.path
        .replace(':companyId', params.companyId)
        .replace('/*', ''),
    );
  }, [navigate, params]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await companyApi.get(params.companyId);
        setCompany(data.company);
      } catch (e) {}
    })();
  }, [params]);

  return (
    <>
      <PrimaryLayout>
        <div>
          <Container>
            <ModuleWrapper
              title={
                <StyledModuleTitle style={{ marginTop: 0 }}>
                  {/*{t('Customer')}*/}
                  {t('Customer Access for company')} {company?.name}
                </StyledModuleTitle>
              }
              actionContent={
                <Row justify='end'>
                  <Col>
                    <Button
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={onClickCreate}
                    >
                      {t('Add access')}
                    </Button>
                  </Col>
                </Row>
              }
              headerPrefix={
                <>
                  <Link to={RouteCodes.home.path}>
                    <Button
                      style={{ paddingLeft: 0 }}
                      type='link'
                      icon={<ArrowLeftOutlined />}
                    >
                      {t('To companies')}
                    </Button>
                  </Link>
                </>
              }
            >
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                }
                rowKey='id'
                bordered={true}
                loading={loading}
                dataSource={data}
                columns={columns}
                // pagination={pagination}
                pagination={false}
              />
            </ModuleWrapper>
          </Container>
        </div>
      </PrimaryLayout>
      <Routes>
        <Route
          path={':id'}
          element={
            <AddOrEditCustomerModal
              open={true}
              allUsers={data}
              usersAllData={usersAllData}
              onClose={onCloseModal}
              afterCreate={() => {
                onCloseModal();
                fetchData();
                fetchUsersAllData();
              }}
            />
          }
        />
      </Routes>
    </>
  );
};

export default ManageCustomers;
