import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import UsersApi from 'libs/api/users';
import AuthService from 'services/Auth';
import { useNavigate } from 'react-router-dom';
import { RouteCodes } from 'common/constants';

const api = new UsersApi();

const AuthContext = createContext({
  user: {},
  onLogout: () => {},
});

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const onLogout = useCallback(() => {
    AuthService.clearToken();
    navigate(RouteCodes.login.path);
  }, [navigate]);


  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get('me');
        setUser(data.user);
      } catch (e) {}
    })();
  }, []);

  return (
    <AuthContext.Provider value={{ user, onLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
