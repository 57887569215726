export const RouteCodes = {
  home: {
    path: '/',
  },
  companies: {
    path: '/campaigns',
  },
  changePassword: {
    path: '/change-password',
  },
  services: {
    path: '/:companyId/services',
  },

  servicesSettings: {
    path: '/:companyId/services/settings',
  },
  servicesSettingsTesting: {
    path: '/:companyId/services/settings/testing',
  },
  servicesConfigs: {
    path: '/service-configs/:id',
  },
  dnsConfig: {
    path: '/dns-configs/:id',
  },
  login: {
    path: '/login',
  },
  manageCustomers: {
    path: '/manage-customers/:companyId/*',
  },
  manageCustomersEdit: {
    path: '/manage-customers/:companyId/:id',
  },
};

export const languages = {
  engKey: 'en',
  itKey: 'it',
};

export const CustomerActionTypes = {
  edit: 'edit',
  disable: 'disable',
  delete: 'delete',
  create: 'delete',
};

export const CompanyActionTypes = {
  edit: 'edit',
  disable: 'disable',
  delete: 'delete',
  create: 'create',
};

export const ServiceTypes = {
  newsletter: 'newsletter',
  transactional: 'transactional',
  user_service: 'user_service',
};

export const ServiceActionTypes = {
  edit: 'edit',
  disable: 'disable',
  delete: 'delete',
  create: 'create',
};

export const NewsletterTypes = {
  sms: 'sms',
  email: 'email',
  whatsapp: 'whatsapp',
};

export const ServicesDataKeys = {
  customer: 'customer',
  service: 'service',
  newsletter: 'newsletter',
  domain: 'domain',
  domainEmail: 'domainEmail',
  domainAllData: 'domainAllData',
  userService: 'userService',
  userServiceReserved: 'userServiceReserved',
  userServiceReservedAllData: 'userServiceReservedAllData',
  userServiceAllData: 'userServiceAllData',
};
