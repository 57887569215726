class ErrorHelper {
  static getErrorsForForm = (data, changedParams = {}) => {
    const errorsData = [];

    Object.entries(data).forEach((i) => {
      // errorsData.push({
      //   name: i.key,
      //   errors: i.messages,
      // });
    });

    data?.forEach(i => {
      errorsData.push({
        name: i.key,
        errors: i.messages,
      });
    });
    return errorsData;
  };
}

export default ErrorHelper;
