import { useCallback, useState } from 'react';

export const useCreateForm = (cb = () => {}) => {
  const [searchValue, setSearchValue] = useState('');

  const onSearch = useCallback((value) => {
    setSearchValue(s => {
      if (!!s && !value && s.length !== 1) return s;
      return value;
    });
  }, []);
  const onChangeSelect = useCallback((value) => {
    setSearchValue('');
    cb(value);
  }, [cb]);

  return {
    searchValue,
    onChangeSelect,
    onSearch,
    setSearchValue,
  };
};
