import styled, { css } from 'styled-components';

export const StyledStep = styled.div`
  width: 36px;
  height: 36px;

  border: 1px solid #6D7885;
  border-radius: 50%;
  

  display: flex;
  align-items: center;
  justify-content: center;
  color: #6D7885;
  
  ${({ active }) => active && css`
    background: #6D7885;
    color: #FFFFFF;
  `}
`;

export const CustomDivider = styled.div`
  position: absolute;
  height: 50px;
  width: 1px;
  left: 18px;
  background: #F2F3F5;
  margin: ${({ my = '9px 0', mx = 0 }) => `${my} ${mx}`};
  
  ${({ variant }) => variant === 'top' && css`
    bottom: calc(100% + 8px);
  `}
`;
