import { StyledModalDescription, StyledModalTitle } from 'components/titles';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EmailSettings } from 'components/modules/services/create/settings';
import { ServicesDataKeys } from 'common/constants';
import DomainEmailsApi from 'libs/api/domain_emails';
import SmtpIPs from 'components/modules/services/create/settings/SmtpIPs';
import DataHelper from 'services/helpers/DataHelper';
import UIHelper from 'services/helpers/UIHelper';
import ErrorHelper from 'services/helpers/ErrorHelper';
import FormHelper from 'services/helpers/FormHelper';

const domainEmailsApi = new DomainEmailsApi();

export const DeleteCustomerContent = ({
  item,
  onClose,
  onDelete,
  inProcess,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  const disable = useMemo(() => {
    return item?.name !== value;
  }, [item, value]);

  const onChange = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setValue(value);
  }, []);

  const handleDelete = useCallback(() => {
    if (onDelete) {
      onDelete(item);
    }
  }, [onDelete, item]);

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <StyledModalTitle>
          {t('Do you want to delete company')}?
        </StyledModalTitle>
        <StyledModalDescription>
          {t('customerDeleteDescription')}
        </StyledModalDescription>
      </Col>
      <Col span={24}>
        <Input
          style={{ maxWidth: 'unset', width: '100%' }}
          size='large'
          placeholder={t('Enter company name')}
          onChange={onChange}
        />
      </Col>
      <Col span={24}>
        <Row style={{ marginTop: 20 }} gutter={[10, 10]}>
          <Col xxl={12} xl={12} lg={12} md={12}>
            <Button
              style={{ width: '100%' }}
              size='large'
              type='default'
              onClick={onClose}
            >
              {t('Cancel')}
            </Button>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12}>
            <Button
              style={{ width: '100%' }}
              size='large'
              type='primary'
              danger={true}
              onClick={handleDelete}
              disabled={disable}
              loading={inProcess}
            >
              {t('Delete')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export const DeleteServiceContent = ({
  item,
  onClose,
  onDelete,
  inProcess,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  const disable = useMemo(() => {
    return (item?.title || item?.email) !== value;
  }, [item, value]);

  const onChange = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setValue(value);
  }, []);

  const handleDelete = useCallback(() => {
    if (onDelete) {
      onDelete(item);
    }
  }, [onDelete, item]);

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <StyledModalTitle>
          {t('Do you want to delete service')}?
        </StyledModalTitle>
      </Col>
      <Col span={24}>
        <Input
          style={{ maxWidth: 'unset', width: '100%' }}
          size='large'
          placeholder={t('Enter service name')}
          onChange={onChange}
        />
      </Col>
      <Col span={24}>
        <Row style={{ marginTop: 20 }} gutter={[10, 10]}>
          <Col xxl={12} xl={12} lg={12} md={12}>
            <Button
              style={{ width: '100%' }}
              size='large'
              type='default'
              onClick={onClose}
            >
              {t('Cancel')}
            </Button>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12}>
            <Button
              style={{ width: '100%' }}
              size='large'
              type='primary'
              danger={true}
              onClick={handleDelete}
              disabled={disable}
              loading={inProcess}
            >
              {t('Delete')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export const AddOrEditCompanyContent = ({
  item,
  onClose,
  inProcess,
  title = 'Create new company',
  onFinish,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const isEdit = !!item?.id;

  const onComplete = useCallback(
    (values) => {
      onFinish(
        {
          ...values,
          id: item?.id,
        },
        form,
      );
    },
    [item, onFinish, form],
  );

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);

  const preparedTitle = isEdit ? t('Update company') : t(title);

  return (
    <>
      <Form
        form={form}
        name='basic'
        initialValues={{
          name: item?.name || '',
          contacts_limit: item?.contacts_limit,
        }}
        onFinish={onComplete}
        autoComplete='off'
        layout={'vertical'}
      >
        <Row>
          <Col span={24}>
            <StyledModalTitle>{preparedTitle}</StyledModalTitle>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('Name')}
              name='name'
              rules={[
                {
                  required: true,
                  message: t('Please input name'),
                },
              ]}
            >
              <Input
                style={{ width: '100%', maxWidth: 'unset' }}
                size={'large'}
                placeholder={t('Name')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name='contacts_limit' label={t('Max contacts')}>
              <Input
                style={{ width: '100%', maxWidth: 'unset' }}
                size={'large'}
                type='number'
                placeholder={t('Max contacts')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row style={{ marginTop: 20 }} gutter={[10, 10]}>
              <Col xxl={12} xl={12} lg={12} md={12}>
                <Button
                  style={{ width: '100%' }}
                  size='large'
                  type='default'
                  onClick={onClose}
                >
                  {t('Cancel')}
                </Button>
              </Col>
              <Col xxl={12} xl={12} lg={12} md={12}>
                <Button
                  style={{ width: '100%' }}
                  size='large'
                  type='primary'
                  htmlType='submit'
                  loading={inProcess}
                >
                  {isEdit ? t('Edit') : t('Create')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export const EditEmailSettings = ({ item, onClose, afterEdit }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [inProcess, setInProcess] = useState(false);
  const [fullData, setFullData] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const { data } = await domainEmailsApi.get(item?.id);
        setFullData(data.domain_email);
        const dataForForm = [];
        Object.keys(data.domain_email).forEach((key) => {
          if (key === 'id') {
            return;
          }
          dataForForm.push({
            name: key,
            value: data.domain_email[key],
          });
        });
        form.setFields(dataForForm);
      } catch (e) {}
    })();
  }, [item, form]);

  const onFinish = useCallback(
    async (values) => {
      try {
        setInProcess(true);
        const dataForRequest = {
          // email: values.email,
          email_alias: values.email_alias,
          day_limit: values.day_limit,
          month_limit: values.month_limit,
        };
        const { ips_with_prefix } = DataHelper.getIPwWithSubnet(values);
        dataForRequest.ips_with_prefix = ips_with_prefix;
        await domainEmailsApi.put(fullData?.id, {
          data: dataForRequest,
        });
        UIHelper.showSuccess(`${item.email} updated`);
        onClose && onClose();
        afterEdit && afterEdit();
      } catch (e) {
        UIHelper.showErrorFromAxios(e);
        form.setFields(
          ErrorHelper.getErrorsForForm(e?.response?.data?.errors || {}, {}),
        );
      } finally {
        setInProcess(false);
      }
    },
    [fullData, item, onClose, form, afterEdit],
  );

  return (
    <>
      <Row justify='center' gutter={[20, 20]}>
        <Col span={24}>
          <StyledModalTitle>{t('Update email service')}</StyledModalTitle>
        </Col>
        <Col span={24}>
          <Form
            layout='vertical'
            form={form}
            initialValues={{
              email: item?.email || '',
              email_alias: item?.email_alias || '',
              day_limit: item?.day_limit || '',
              month_limit: item?.month_limit || '',
              ...(item || {}),
            }}
            onFinish={onFinish}
            onFieldsChange={FormHelper.resetFormsFieldErrors(form, [
              'day_limit',
              'month_limit',
            ])}
          >
            <Row gutter={[]} align='middle' justify='center'>
              <Col>
                <Form.Item
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  label={'Email'}
                  name='email'
                  style={{ marginBottom: 0 }}
                >
                  <Input disabled={true} size='large' placeholder='email' />
                </Form.Item>
              </Col>
              <Col>
                <Typography.Text>{item.domain}</Typography.Text>
              </Col>
            </Row>
            <Row justify={'center'}>
              <Col>
                <Form.Item
                  label='Alias'
                  name='email_alias'
                  style={{ marginBottom: 20, marginTop: 20 }}
                >
                  <Input size='large' placeholder='alias' />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }} justify='center'>
              <Col>
                <Row gutter={[12, 12]} justify='space-between' wrap={false} style={{ width: 300 }}>
                  <Col style={{ paddingLeft: 0 }}>
                    <Form.Item
                      label={t('max/day')}
                      style={{ minWidth: 'unset', width: 142 }}
                      name='day_limit'
                    >
                      <Input
                        type='number'
                        style={{ minWidth: 'unset', width: 142 }}
                        size='large'
                        placeholder={t('max/day')}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name='month_limit' label={t('max/month')}>
                      <Input
                        type='number'
                        style={{ minWidth: 'unset', width: 142 }}
                        size='large'
                        placeholder={t('max/month')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify='center'>
              <Col>
                <SmtpIPs
                  form={form}
                  dataByArray={fullData?.ips_with_prefix}
                  ips_with_prefix={fullData?.ips_with_prefix}
                />
              </Col>
            </Row>

            <Col span={24}>
              <Row style={{ marginTop: 20 }} gutter={[10, 10]}>
                <Col xxl={12} xl={12} lg={12} md={12}>
                  <Button
                    style={{ width: '100%' }}
                    size='large'
                    type='default'
                    onClick={onClose}
                  >
                    {t('Cancel')}
                  </Button>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12}>
                  <Button
                    style={{ width: '100%' }}
                    size='large'
                    type='primary'
                    loading={inProcess}
                    htmlType='submit'
                  >
                    {t('Save')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export const DisableServiceContent = ({
  item,
  onClose,
  onDisable,
  inProcess,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  const disable = useMemo(() => {
    return (item?.title || item?.email) !== value;
  }, [item, value]);

  const onChange = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setValue(value);
  }, []);

  const handleDelete = useCallback(() => {
    if (onDisable) {
      onDisable(item);
    }
  }, [onDisable, item]);

  const title = !!item?.status
    ? t('Do you want to disable service')
    : t('Do you want to enable service');

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <StyledModalTitle>{title}?</StyledModalTitle>
      </Col>
      <Col span={24}>
        <Input
          style={{ maxWidth: 'unset', width: '100%' }}
          size='large'
          placeholder={t('Enter service name')}
          onChange={onChange}
        />
      </Col>
      <Col span={24}>
        <Row style={{ marginTop: 20 }} gutter={[10, 10]}>
          <Col xxl={12} xl={12} lg={12} md={12}>
            <Button
              style={{ width: '100%' }}
              size='large'
              type='default'
              onClick={onClose}
            >
              {t('Cancel')}
            </Button>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12}>
            <Button
              style={{ width: '100%' }}
              size='large'
              type='primary'
              danger={!!item.status}
              onClick={handleDelete}
              disabled={disable}
              loading={inProcess}
            >
              {item.status ? t('Disable') : t('Enable')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export const DisableCustomerContent = ({
  item,
  onClose,
  onDisable,
  inProcess,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  const disable = useMemo(() => {
    return item?.name !== value;
  }, [item, value]);

  const onChange = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setValue(value);
  }, []);

  const handleDelete = useCallback(() => {
    if (onDisable) {
      onDisable(item);
    }
  }, [onDisable, item]);

  const title = !!item?.status
    ? t('Do you want to disable company')
    : t('Do you want to enable company');

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <StyledModalTitle>{title}?</StyledModalTitle>
      </Col>
      <Col span={24}>
        <Input
          style={{ maxWidth: 'unset', width: '100%' }}
          size='large'
          placeholder={t('Enter company name')}
          onChange={onChange}
        />
      </Col>
      <Col span={24}>
        <Row style={{ marginTop: 20 }} gutter={[10, 10]}>
          <Col xxl={12} xl={12} lg={12} md={12}>
            <Button
              style={{ width: '100%' }}
              size='large'
              type='default'
              onClick={onClose}
            >
              {t('Cancel')}
            </Button>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={12}>
            <Button
              style={{ width: '100%' }}
              size='large'
              type='primary'
              danger={!!item.status}
              onClick={handleDelete}
              disabled={disable}
              loading={inProcess}
            >
              {item.status ? t('Disable') : t('Enable')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export const SMSConfigModal = ({ item }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Typography.Title level={5}>
            {t('Method')}: POST
          </Typography.Title>
          <pre>
            <code>
              {process.env.REACT_APP_API_URL}/sms/send_simple
            </code>
          </pre>
        </Col>
        <Col span={24}>
          <Typography.Title level={5} style={{ marginTop: 1 }}>
            {t('Headers')}
          </Typography.Title>
          <Row gutter={[10, 10]}>
            <Col>Content type: </Col>
            <Col>application/json</Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col>Auth token: </Col>
            <Col>[USER TOKEN]</Col>
          </Row>
        </Col>
        <Col span={24}>
          <Typography.Title level={5} style={{ marginTop: 1 }}>
            {t('Body')}
          </Typography.Title>
          <pre>
            <code>
            <p>
              <span>"send_to"</span>: <span>"999999999"</span>,
            </p>
            <p>
              <span>"message"</span>: <span>"Message"</span>,
            </p>
            <p>
              <span>"user_service_id"</span>: <span>{item?.id}</span>
            </p>
          </code>
          </pre>
        </Col>
      </Row>
    </>
  );
};
