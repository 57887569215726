import {
  PrivacyText,
  StyledFooter,
  StyledLinks,
  StyledMapLink,
} from 'components/layout/footer/styled';
import Container from 'components/container';
import { Col, Row, Space } from 'antd';
import { ReactComponent as EmailSVG } from 'assets/images/email.svg';
import { ReactComponent as WhatsUppSVG } from 'assets/images/whatsupp.svg';

const PrimaryFooter = () => {
  return (
    <StyledFooter>
      <Container>
        <Row
          gutter={[20, 20]}
          align='middle'
          style={{ height: '100%' }}
          justify='space-between'
        >
          <Col>
            <StyledLinks href='mailto:info@ritail.hk'>
              <Space>
                <EmailSVG style={{ marginTop: 3 }} />
                info@ritail.hk
              </Space>
            </StyledLinks>
          </Col>
          <Col>
            <StyledLinks href='tel:+852 2319 4169'>
              <Space>
                <WhatsUppSVG style={{ marginTop: 3 }} />
                +852 2319 4169
              </Space>
            </StyledLinks>
          </Col>
          <Col>
            <StyledMapLink href='https://goo.gl/maps/Pn1oL4Gtds7FtDZy6'>
              <span>
                Unit 1701-1702, 17/F 135 Bonham Strand Trade Centre
              </span>
            </StyledMapLink>
            <br />
            <StyledMapLink as='p' link={false}>
              Sheung Wan, Hong Kong
            </StyledMapLink>
          </Col>
          <Col>
            <PrivacyText>Cookie policy</PrivacyText>
          </Col>
          <Col>
            <PrivacyText>Privacy policy</PrivacyText>
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  );
};

export default PrimaryFooter;
