import Api from 'libs/api/index';

class SMSApi extends Api {
  constructor() {
    super('sms');
  }

  sendTestSMS = params => {
    return this.buildQuery('post', params, 'send_test');
  };
}

export default SMSApi;
