import { useState, useCallback, useEffect } from 'react';
import CompaniesApi from 'libs/api/companies';

const api = new CompaniesApi();

export const useCompaniesApi = (autoFetch = false ) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState(false);

  const fetchData = useCallback(async (params = {}) => {
    setLoading(true);
    try {
      const { data } = await api.list(params);
      setData(data.companies);
      setPagination(data.pagination);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, []);

  const deleteItem = useCallback((id) => {
    return api.destroy(id);
  }, []);

  const updateItem = useCallback((id, params) => {
    return api.put(id, params);
  }, []);

  useEffect(() => {
    if (autoFetch) {
      (async () => {
        try {
          await fetchData();
        } catch (e) {}
      })();
    }
  }, [autoFetch, fetchData]);

  return {
    fetchData,
    loading,
    pagination,
    data,
    deleteItem,
    updateItem,
  };
};
