import AuthService from 'services/Auth';

class FormHelper {
  static resetFormsFieldErrors = (form, onlyResetFields) => (changedFields) => {
    if (changedFields[0]) {
      const name = changedFields[0].name[0];
      if (!onlyResetFields) {
        form.setFields([
          {
            name,
            errors: [],
          },
        ]);
      } else {
        if (onlyResetFields.includes(name)) {
          form.setFields([
            {
              name,
              errors: [],
            },
          ]);
        }
      }
    }
  };

  static jsonToFormData = (json, suffix, fileKeys = []) => {
    const formData = new FormData();
    Object.keys(json).forEach((key) => {
      const isFile = fileKeys.includes(key);
      if (isFile) {
        formData.append(
          suffix ? `${suffix}[${key}]` : key,
          (json[key] && json[key][0]?.originFileObj) || 'null',
        );

        return;
      }
      formData.append(suffix ? `${suffix}[${key}]` : key, json[key]);
    });
    return formData;
  };

  static normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  static beforeUpload = () => false;
}

export default FormHelper;
