import Container from 'components/container';
import { Button, Col, Row } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { StyledModuleTitle } from 'components/titles';
import { SectionItem } from 'components/modules/services/index';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useServiceContext } from 'contexts/service';
import { NewsletterTypes, ServicesDataKeys } from 'common/constants';
import ServiceTestForm from 'components/modules/services/create/test';

const ServiceTest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { serviceData } = useServiceContext();
  const [searchParams] = useSearchParams();
  const skipTestMethod = searchParams.get('skipTestMethod');

  const showTable = useMemo(() => {
    return (
      serviceData[ServicesDataKeys.newsletter] === NewsletterTypes.email &&
      !!serviceData[ServicesDataKeys.domainEmail]?.id &&
      !+serviceData[ServicesDataKeys.domainEmail]?.set_default_ip
    );
  }, [serviceData]);

  const onBack = useCallback(
    (e) => {
      e.preventDefault();
      navigate(-1);
    },
    [navigate],
  );

  const title = useMemo(() => {
    let text = t('Settings of mail method');
    if (showTable) {
      return t('Email config');
    }
    if (serviceData[ServicesDataKeys.newsletter] === 'email') {
      // text = t('Test of mail method');
      text = t('Send a test message');
    }

    if (serviceData[ServicesDataKeys.newsletter] === 'sms') {
      // text = t('Test of sms method');
      text = t('Send a test message');
    }

    return text;
  }, [t, serviceData, showTable]);

  return (
    <div>
      <Container>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <SectionItem
              rowProps={{
                align: 'middle',
                justify: 'start',
                style: { marginBottom: 20 },
              }}
              prefix={
                <>
                  <a href='/' onClick={onBack}>
                    <Button
                      style={{ paddingLeft: 0 }}
                      type='link'
                      icon={<ArrowLeftOutlined />}
                    >
                      {t('Go back')}
                    </Button>
                  </a>
                </>
              }
            >
              <StyledModuleTitle style={{ margin: 0 }}>
                {title}
              </StyledModuleTitle>
            </SectionItem>
          </Col>
          <Col span={24}>
            <Row gutter={[50, 50]}>
              <Col span={24}>
                <SectionItem
                  rowProps={{ align: 'middle', justify: 'start' }}
                  prefix={<></>}
                >
                  <ServiceTestForm
                    variant={serviceData[ServicesDataKeys.newsletter]}
                  />
                </SectionItem>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServiceTest;
