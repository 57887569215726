import styled from 'styled-components';
import { Layout } from 'antd';

export const StyledContent = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;

export const StyledLayout = styled(Layout)`
  min-height: 100vh;
  background: #ffffff;
`;
