import { Col, Row, Dropdown, Space, Typography } from 'antd';
import { StyledHeader } from 'components/layout/header/styled';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { DownOutlined } from '@ant-design/icons';
import Container from 'components/container';
import { Link } from 'react-router-dom';
import useLogin from 'pages/login/useLogin';
import { useAuthContext } from 'contexts/auth';
import { RouteCodes } from 'common/constants';
import { useTranslation } from 'react-i18next';

const PrimaryHeader = () => {
  const { t } = useTranslation();
  const { user, onLogout } = useAuthContext();
  const items = [
    {
      key: '2',
      label: <Link to={RouteCodes.changePassword.path}>{t('Change password')}</Link>,
    },
    {
      key: '1',
      label: <div style={{ width: '100%' }} onClick={onLogout}>Logout</div>,
    },

  ];
  return (
    <StyledHeader>
      <Container>
        <Row justify='space-between' align='middle' style={{ height: '100%' }}>
          <Col style={{ display: 'flex' }}>
            <Link to={'/'} style={{ display: 'flex', alignItems: 'center' }}>
              <Logo />
            </Link>
          </Col>
          <Col>
            <Dropdown
              menu={{
                items,
              }}
            >
              <Typography.Text>
                <Space>
                  {user?.email}
                  <DownOutlined />
                </Space>
              </Typography.Text>
            </Dropdown>
          </Col>
        </Row>
      </Container>
    </StyledHeader>
  );
};

export default PrimaryHeader;
