import { createContext, useCallback, useContext, useState } from 'react';
import { ServicesDataKeys } from 'common/constants';

const ServiceContext = createContext({
  serviceData: {
    [ServicesDataKeys.newsletter]: '',
    [ServicesDataKeys.customer]: '',
    [ServicesDataKeys.domain]: '',
    [ServicesDataKeys.userService]: '',
    [ServicesDataKeys.userServiceReserved]: '',
    [ServicesDataKeys.userServiceReservedAllData]: {},
    [ServicesDataKeys.userServiceAllData]: {},
    [ServicesDataKeys.domainAllData]: {},
    [ServicesDataKeys.domainEmail]: {},
  },
  step: 1,
  completedSteps: {
    1: false,
    2: false,
    3: false,
  },
  onChangeStep: () => {},
  onChangeServiceData: () => {},
  onChangeCompletedStep: () => {},
  resetState: () => {},
});


export const ServiceContextProvider = ({ children }) => {
  const [serviceData, setServiceData] = useState({
    [ServicesDataKeys.newsletter]: '',
    [ServicesDataKeys.customer]: '',
  });
  const [step, setStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState({
    1: false,
    2: false,
    3: false,
  });

  const onChangeServiceData = useCallback((key, value) => {
    setServiceData((s) => ({
      ...s,
      [key]: value,
    }));
  }, []);

  const onChangeStep = useCallback((value) => {
    setStep(value);
  }, []);

  const onChangeCompletedStep = useCallback((key, value) => {
    setCompletedSteps((s) => ({
      ...s,
      [key]: value,
    }));
  }, []);

  const resetState = useCallback(() => {
    setServiceData({
      [ServicesDataKeys.newsletter]: '',
      [ServicesDataKeys.customer]: '',
      [ServicesDataKeys.domain]: '',
      [ServicesDataKeys.userService]: '',
      [ServicesDataKeys.userServiceReserved]: '',
      [ServicesDataKeys.userServiceReservedAllData]: {},
      [ServicesDataKeys.userServiceAllData]: {},
      [ServicesDataKeys.domainAllData]: {},
      [ServicesDataKeys.domainEmail]: {},
    });
    setCompletedSteps({
      1: false,
      2: false,
      3: false,
    });
    setStep(1);
  }, []);

  return (
    <ServiceContext.Provider
      value={{
        onChangeServiceData,
        onChangeStep,
        onChangeCompletedStep,
        step,
        serviceData,
        completedSteps,
        resetState,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};

export const useServiceContext = () => {
  return useContext(ServiceContext);
};
