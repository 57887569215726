import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTableHeader, StyledTableText } from 'components/titles';
import {
  Dropdown,
  Typography,
  notification as notificationApi,
  Row,
  Col,
  Button,
  Divider,
  Tooltip,
} from 'antd';
import {
  StopOutlined,
  EditOutlined,
  DeleteOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {
  CompanyActionTypes,
  RouteCodes,
  ServiceActionTypes,
  ServiceTypes,
} from 'common/constants';
import { Link } from 'react-router-dom';
import { useCompaniesApi } from 'hooks/use-companies-api';
import DataHelper from 'services/helpers/DataHelper';
import UIHelper from 'services/helpers/UIHelper';
import CompaniesApi from 'libs/api/companies';
import ErrorHelper from 'services/helpers/ErrorHelper';
import UserServices from 'libs/api/user_services';
import DomainEmailsApi from 'libs/api/domain_emails';

const api = new CompaniesApi();
const userServicesApi = new UserServices();
const domainEmailsApi = new DomainEmailsApi();

const ServiceItem = ({
  i,
  onClick,
  onClickSms,
  serviceType,
  showConfig = true,
}) => {
  const { t } = useTranslation();
  const items = useMemo(() => {
    const items = [];
    if (i.hasOwnProperty('is_default_ip') && !i.is_default_ip && showConfig) {
      items.push({
        label: (
          <Link to={RouteCodes.servicesConfigs.path.replace(':id', i.id)}>
            {t('View configs')}
          </Link>
        ),
        key: 'view_configs',
      });
    }
    if (serviceType === ServiceTypes.user_service) {
      items.push({
        label: t('View configs'),
        key: 'view_configs',
        onClick: () => {
          if (onClickSms) {
            onClickSms(i);
          }
        },
      });
    }
    if (serviceType !== ServiceTypes.user_service) {
      items.push({
        label: (
          <Link to={RouteCodes.dnsConfig.path.replace(':id', i.domain_id)}>
            {t('View DNS configs')}
          </Link>
        ),
        key: 'view_dns_configs',
      });
    }
    return [
      ...items,
      {
        label: <Typography.Link>{t('Edit')}</Typography.Link>,
        key: 'edit',
        onClick: () =>
          onClick((s) => ({
            ...s,
            item: i,
            action: ServiceActionTypes.edit,
            serviceType,
          })),
      },
      {
        label: (
          <Typography.Text>
            {i.status ? t('Disable') : t('Enable')}
          </Typography.Text>
        ),
        key: 'disable',
        onClick: () =>
          onClick({
            item: i,
            action: ServiceActionTypes.disable,
            serviceType,
          }),
      },
      {
        label: <Typography.Text type='danger'>{t('Delete')}</Typography.Text>,
        key: 'delete',
        onClick: () =>
          onClick({
            item: i,
            action: ServiceActionTypes.delete,
            serviceType,
          }),
      },
    ];
  }, [onClick, t, i, serviceType, showConfig]);

  const title = useMemo(() => {
    switch (serviceType) {
      case ServiceTypes.transactional:
      case ServiceTypes.newsletter:
        return (
          <Tooltip
            title={
              i.status ? null : (
                <span style={{ opacity: 1 }}>{t('Disabled')}</span>
              )
            }
          >
            {i.name || i.email}
            {!i.status && (
              <>
                <br />
                <span
                  style={{
                    color: i.status ? '' : '#f42121',
                  }}
                >
                  ({t('Disabled')})
                </span>
              </>
            )}
          </Tooltip>
        );
      case ServiceTypes.user_service:
        return (
          <div>
            <span
              style={{
                color: i.status ? '' : '#f42121',
              }}
            >
              Service: {i.title}
            </span>{' '}
            {!i.status && (
              <>
                <br />
                <span
                  style={{
                    color: i.status ? '' : '#f42121',
                  }}
                >
                  ({t('Disabled')})
                </span>
              </>
            )}
            {i.reserved_service_title && (
              <>
                <br />
                <span>Backup: {i.reserved_service_title}</span>
              </>
            )}
          </div>
        );
        return (
          <Tooltip
            title={
              <span style={{ textTransform: 'capitalize' }}>
                {i.variant === 'reserved' ? 'backup' : i.variant}{' '}
                <span style={{ opacity: 1 }}>
                  {!i.status ? `(${t('Disabled')})` : ''}
                </span>
              </span>
            }
          >
            {i.title}
          </Tooltip>
        );
      default:
        return i.title;
    }
  }, [serviceType, i, t]);

  return (
    <Col
      key={i.id}
      span={24}
      // style={{ backgroundColor: i.status ? '' : 'black' }}
    >
      <Row justify='space-between' align='middle' style={{ paddingRight: 10 }}>
        <Col span={20}>
          <StyledTableText
            ellipsis={true}
            style={
              serviceType !== ServiceTypes.user_service
                ? {
                    color: i.status ? '' : '#f42121',
                  }
                : {}
            }
          >
            {title} {/*{!i.status ? (*/}
            {/*  <>*/}
            {/*    <br />*/}
            {/*    <span style={{ opacity: 1, color: '#866666' }}>*/}
            {/*      ({t('Disabled')})*/}
            {/*    </span>*/}
            {/*  </>*/}
            {/*) : (*/}
            {/*  ''*/}
            {/*)}*/}
          </StyledTableText>
        </Col>
        <Col span={4}>
          <Dropdown menu={{ items }} trigger={['click']} placement='right'>
            <Button type='primary' size='small' icon={<SettingOutlined />} />
          </Dropdown>
        </Col>
      </Row>
    </Col>
  );
};

export const useCompanies = () => {
  const { t } = useTranslation();
  const { pagination, loading, data, fetchData, deleteItem, updateItem } =
    useCompaniesApi(true);
  const [inProcess, setInProcess] = useState(false);
  const [smsConfigModal, setSmsConfigModal] = useState({
    item: null,
  });
  const [selectedElement, setSelectedElement] = useState({
    item: null,
    forceShow: false,
    action: 'disable',
  });

  const [selectedService, setSelectedService] = useState({
    item: null,
    forceShow: false,
    action: 'edit',
    serviceType: '',
  });

  const onCloseSmsModal = useCallback(() => {
    setSmsConfigModal((s) => ({
      ...s,
      item: null,
    }));
  }, []);

  const columns = useMemo(
    () => [
      {
        title: <StyledTableHeader>{t('Client')}</StyledTableHeader>,
        dataIndex: 'name',
        width: 210,
        render: (data, row) => {
          const items = [
            {
              label: <Typography.Link>{t('Edit')}</Typography.Link>,
              key: 'edit',
              onClick: () =>
                setSelectedElement((s) => ({
                  ...s,
                  item: row,
                  action: CompanyActionTypes.edit,
                })),
            },
            {
              label: (
                <Typography.Text type='secondary'>
                  {row.status ? t('Disable') : t('Enable')}
                </Typography.Text>
              ),
              key: 'disable',
              onClick: () =>
                setSelectedElement({
                  item: row,
                  action: CompanyActionTypes.disable,
                }),
            },
            {
              label: (
                <Typography.Text type='danger'>{t('Delete')}</Typography.Text>
              ),
              key: 'delete',
              onClick: () =>
                setSelectedElement({
                  item: row,
                  action: CompanyActionTypes.delete,
                }),
            },
          ];
          return (
            <Dropdown menu={{ items }}>
              <StyledTableText>
                {data}{' '}
                <span style={{ opacity: 1, color: '#866666' }}>
                  {!row.status ? `(${t('Disabled')})` : ''}
                </span>
              </StyledTableText>
            </Dropdown>
          );
        },
      },
      {
        title: <StyledTableHeader>{t('SMS services')}</StyledTableHeader>,
        dataIndex: 'user_services',
        width: 210,
        render: (data) => {
          return (
            <Row gutter={[10, 10]} style={{ paddingTop: 10 }}>
              {data?.map((i) => (
                <Fragment key={i.id}>
                  <ServiceItem
                    i={i}
                    showConfig={false}
                    onClick={setSelectedService}
                    serviceType={ServiceTypes.user_service}
                    onClickSms={(i) => {
                      setSmsConfigModal((s) => ({
                        ...s,
                        item: i,
                      }));
                    }}
                  />
                  <Col span={24}>
                    <Divider style={{ margin: 0 }} />
                  </Col>
                </Fragment>
              ))}
            </Row>
          );
        },
      },
      {
        title: (
          <StyledTableHeader>{t('Transactional emails')}</StyledTableHeader>
        ),
        dataIndex: 'notifications',
        width: 250,
        render: (data) => (
          <Row gutter={[10, 10]} style={{ paddingTop: 10 }}>
            {data?.map((i) => (
              <Fragment key={i.id}>
                <ServiceItem
                  i={i}
                  onClick={setSelectedService}
                  serviceType={ServiceTypes.transactional}
                />
                <Col span={24}>
                  <Divider style={{ margin: 0 }} />
                </Col>
              </Fragment>
            ))}
          </Row>
        ),
      },
      {
        title: <StyledTableHeader>{t('Newsletter')}</StyledTableHeader>,
        dataIndex: 'newsletters',
        width: 210,
        render: (data) => {
          return (
            <Row gutter={[10, 10]} style={{ paddingTop: 10 }}>
              {data?.map((i) => (
                <Fragment key={i.id}>
                  <ServiceItem
                    i={i}
                    onClick={setSelectedService}
                    serviceType={ServiceTypes.transactional}
                  />
                  <Col span={24}>
                    <Divider style={{ margin: 0 }} />
                  </Col>
                </Fragment>
              ))}
            </Row>
          );
        },
      },
      {
        title: <StyledTableHeader>{t('Actions')}</StyledTableHeader>,
        dataIndex: '_',
        width: 210,
        render: (_, row) => {
          // if (!row.status) {
          //   return <></>;
          // }
          return (
            <Row gutter={[0, 8]} style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Col span={24}>
                <Row justify='center' gutter={[8, 8]}>
                  <Col>
                    <Button
                      type='primary'
                      icon={<EditOutlined />}
                      onClick={() => {
                        setSelectedElement((s) => ({
                          ...s,
                          item: row,
                          action: CompanyActionTypes.edit,
                        }));
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      icon={<StopOutlined />}
                      onClick={() => {
                        setSelectedElement({
                          item: row,
                          action: CompanyActionTypes.disable,
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      danger={true}
                      type='primary'
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        setSelectedElement({
                          item: row,
                          action: CompanyActionTypes.delete,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify='center' gutter={[0, 0]}>
                  {!!row.status && (
                    <Col>
                      <Link
                        to={RouteCodes.services.path.replace(
                          ':companyId',
                          row.id,
                        )}
                      >
                        <Button size='small' type='link'>
                          Create new service
                        </Button>
                      </Link>
                    </Col>
                  )}
                  <Col>
                    <Link
                      to={RouteCodes.manageCustomers.path
                        .replace(':companyId', row.id)
                        .replace('/*', '')}
                    >
                      <Button size='small' type='link'>
                        Manage access
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        },
      },
    ],
    [t],
  );

  const onCloseModal = useCallback(
    (message = 'Deleted', showNotification = true) => {
      setSelectedElement({
        item: null,
        forceShow: false,
        action: CompanyActionTypes.disable,
      });
    },
    [],
  );

  const onCloseServiceModal = useCallback(() => {
    setSelectedService((s) => ({
      ...s,
      item: null,
      forceShow: false,
      action: ServiceActionTypes.disable,
      serviceType: '',
    }));
  }, []);

  const showModal = useMemo(() => {
    return !!selectedElement?.item?.id || !!selectedElement.forceShow;
  }, [selectedElement]);

  const showServiceModal = useMemo(() => {
    return (
      (!!selectedService?.item?.id || !!selectedService.forceShow) &&
      selectedService.action !== ServiceActionTypes.edit
    );
  }, [selectedService]);

  const showSMSModal = useMemo(() => {
    return (
      (!!selectedService?.item?.id || !!selectedService.forceShow) &&
      selectedService.serviceType === ServiceTypes.user_service &&
      selectedService.action === ServiceActionTypes.edit
    );
  }, [selectedService]);

  const showEmailsModal = useMemo(() => {
    return (
      (!!selectedService?.item?.id || !!selectedService.forceShow) &&
      (selectedService.serviceType === ServiceTypes.transactional ||
        selectedService.serviceType === ServiceTypes.newsletter) &&
      selectedService.action === ServiceActionTypes.edit
    );
  }, [selectedService]);

  const onDelete = useCallback(
    async (item) => {
      try {
        setInProcess(true);
        await deleteItem(item.id);
        onCloseModal();
        fetchData();
        notificationApi.info({
          message: 'Deleted',
          placement: 'bottomLeft',
          icon: <StopOutlined />,
        });
      } catch (e) {
        UIHelper.showErrorFromAxios(e, notificationApi);
      } finally {
        setInProcess(false);
      }
    },
    [deleteItem, fetchData, onCloseModal],
  );

  const onDisable = useCallback(
    async (item, disable = true) => {
      try {
        setInProcess(true);
        await updateItem(item.id, {
          data: {
            status: !item.status,
          },
        });
        onCloseModal();
        fetchData();
        notificationApi[item.status ? 'info' : 'success']({
          message: item.status ? t('Disabled') : t('Enabled'),
          placement: 'bottomLeft',
          icon: !item.status ? undefined : <StopOutlined />,
        });
      } catch (e) {
        UIHelper.showErrorFromAxios(e, notificationApi);
      } finally {
        setInProcess(false);
      }
    },
    [updateItem, onCloseModal, fetchData, t],
  );

  const onCreate = useCallback(
    async (values, formRef) => {
      try {
        setInProcess(true);
        const { id, ...allData } = values;
        if (!!id) {
          await api.put(id, {
            data: {
              ...allData,
              contacts_limit: values.contacts_limit,
            },
          });
        } else {
          await api.create({
            data: {
              ...values,
              contacts_limit: values.contacts_limit,
            },
          });
        }
        onCloseModal(undefined, false);
        setTimeout(() => {
          notificationApi.success({
            message: !!id
              ? `${values.name} ${t('successfully updated')}`
              : t('Company successfully created'),
          });
        }, 100);
        fetchData();
      } catch (e) {
        UIHelper.showErrorFromAxios(e, notificationApi);
        if (formRef) {
          formRef.setFields(
            ErrorHelper.getErrorsForForm(e?.response?.data?.errors || {}, {}),
          );
        }
      } finally {
        setInProcess(false);
      }
    },
    [t, onCloseModal, fetchData],
  );

  const onClickCreate = useCallback(() => {
    setSelectedElement((s) => ({
      ...s,
      item: null,
      forceShow: true,
      action: CompanyActionTypes.create,
    }));
  }, []);

  const onDeleteService = useCallback(async () => {
    try {
      if (selectedService.serviceType === ServiceTypes.user_service) {
        setInProcess(true);
        await userServicesApi.destroy(selectedService.item?.id);
        onCloseServiceModal();
        UIHelper.showSuccess(`${selectedService.item?.title} deleted`);
        fetchData();
      }
      if (
        selectedService.serviceType === ServiceTypes.transactional ||
        selectedService.serviceType === ServiceTypes.newsletter
      ) {
        setInProcess(true);
        await domainEmailsApi.destroy(selectedService.item?.id);
        onCloseServiceModal();
        UIHelper.showSuccess(
          `${
            selectedService.item?.title || selectedService.item?.email || ''
          } deleted`,
        );
        fetchData();
      }
    } catch (e) {
      UIHelper.showErrorFromAxios(e);
    } finally {
      setInProcess(false);
    }
  }, [selectedService, onCloseServiceModal, fetchData]);

  const onDisableService = useCallback(
    async (item, disable = true) => {
      try {
        if (selectedService.serviceType === ServiceTypes.user_service) {
          setInProcess(true);
          await userServicesApi.put(selectedService.item?.id, {
            data: {
              status: !item.status,
            },
          });
          onCloseServiceModal();
          fetchData();
          const message = item.status ? t('Disabled') : t('Enabled');
          !item.status
            ? UIHelper.showSuccess(message)
            : UIHelper.showWarning(message);
        }

        if (
          selectedService.serviceType === ServiceTypes.transactional ||
          selectedService.serviceType === ServiceTypes.newsletter
        ) {
          setInProcess(true);
          await domainEmailsApi.put(selectedService.item?.id, {
            data: {
              status: !item.status,
            },
          });
          onCloseServiceModal();
          fetchData();
          const message = item.status ? t('Disabled') : t('Enabled');
          !item.status
            ? UIHelper.showSuccess(message)
            : UIHelper.showWarning(message);
        }
      } catch (e) {
        console.log('e', e);
        UIHelper.showErrorFromAxios(e);
      } finally {
        setInProcess(false);
      }
    },
    [selectedService, onCloseServiceModal, fetchData, t],
  );

  return {
    columns,
    loading,
    data,
    pagination,
    onCloseModal,
    showModal,
    selectedElement,
    fetchData,
    onDelete,
    inProcess,
    onDisable,
    onClickCreate,
    onCreate,
    selectedService,
    onCloseServiceModal,
    showServiceModal,
    onDeleteService,
    showSMSModal,
    onDisableService,
    showEmailsModal,
    smsConfigModal,
    onCloseSmsModal,
  };
};
