import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import Routes from 'routes';
import { RecoilRoot } from 'recoil';
import { ServiceContextProvider } from 'contexts/service';
import { AuthContextProvider } from 'contexts/auth';

function App() {
  return (
    <>
      <BrowserRouter>
        <RecoilRoot>
          <AuthContextProvider>
            <ServiceContextProvider>
              <ConfigProvider
                theme={{
                  components: {
                    Input: {
                      colorBgContainer: '#F2F3F5',
                    },
                    Select: {
                      colorBgContainer: '#F2F3F5',
                    },
                    Button: {
                      colorPrimary: '#2D70BE',
                      colorText: '#2D70BE',
                      colorBgBase: '#F2F3F5',
                      colorBgContainer: '#F2F3F5',
                      colorBorder: '#F2F3F5',
                      borderRadius: '8px',
                      paddingContentHorizontal: 25,
                    },
                    Table: {
                      borderRadiusOuter: 2,
                      borderRadius: 1,
                    },
                  },
                }}
              >
                <Routes />
              </ConfigProvider>
            </ServiceContextProvider>
          </AuthContextProvider>
        </RecoilRoot>
      </BrowserRouter>
    </>
  );
}

export default App;
