import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useState } from 'react';

const LinkText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #2D70BE;
  cursor: pointer;
`;

const ServerDetailsBlock = styled.div`
  background: #FFFFFF;
  /* VKUI · Light/Elevation 3 */

  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  position: absolute;
  left: calc(100% + 20px);
  top: -60%;
  padding: 16px;
`;

const Wrapper = styled.div`
  position: relative;
  max-width: 300px;
  margin-bottom: 20px;
`;

const ShowServerDetails = ({ ips }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  if (!ips) {
    return null;
  }

  return (
    <Wrapper>
      <Row justify='end'>
        <Col>
          <LinkText onClick={() => setShow(s => !s)}>
            {t('Show IP')}
          </LinkText>
        </Col>
      </Row>
      {show && (
        <ServerDetailsBlock>
          {ips}
        </ServerDetailsBlock>
      )}
    </Wrapper>
  );
};

export default ShowServerDetails;
