import { Button, Col, Form, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useServiceContext } from 'contexts/service';
import { ServicesDataKeys } from 'common/constants';
import { useCreateForm } from 'hooks/use-create-form';
import UserServices from 'libs/api/user_services';
import { MainButton } from 'components/buttons/main';
import NewSMSMethodModal from 'components/myModals/newSMSMethodModal';

const api = new UserServices();

const SmsService = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [userServices, setUserServices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    serviceData: { userService, userServiceAllData },
    onChangeServiceData,
    onChangeCompletedStep,
    completedSteps,
  } = useServiceContext();

  const onChangeUserService = useCallback(
    (value) => {
      onChangeServiceData(ServicesDataKeys.userService, value);
      const f = userServices.find((i) => i.id === +value);
      onChangeServiceData(ServicesDataKeys.userServiceAllData, f);
      onChangeServiceData(
        ServicesDataKeys.userServiceReserved,
        f?.reserved_service?.id || '',
      );
      onChangeServiceData(
        ServicesDataKeys.userServiceReservedAllData,
        f?.reserved_service || {},
      );
    },
    [onChangeServiceData, userServices],
  );

  const changeAfterSelect = useCallback(
    (value) => {
      onChangeUserService(value);
      onChangeCompletedStep(2, false);
    },
    [onChangeUserService, onChangeCompletedStep],
  );

  const { searchValue, onSearch, onChangeSelect, setSearchValue } =
    useCreateForm(changeAfterSelect);

  const onClose = useCallback(() => {
    setShowModal(false);

    if (setSearchValue) {
      setSearchValue('');
    }
  }, [setSearchValue]);

  const fetchUserServices = useCallback(async () => {
    try {
      const { data } = await api.list({
        params: {
          company_id: params.companyId,
          by_variant: 'primary',
        },
      });
      setUserServices(data.user_services);
    } catch (e) {}
  }, [params]);

  useEffect(() => {
    (async () => {
      try {
        await fetchUserServices();
      } catch (e) {}
    })();
    //eslint-disable-next-line
  }, []);

  const showCreateButton = useMemo(() => {
    return !userService;
  }, [userService]);

  const createButtonDisabled = useMemo(() => {
    return !searchValue || !!userService;
  }, [userService, searchValue]);

  const onClickCreate = useCallback(() => {
    setShowModal((s) => !s);
  }, []);

  const showNextButton = useMemo(() => {
    return !!userService && !completedSteps[2];
  }, [userService, completedSteps]);

  const onNavigateToTest = useCallback(() => {
    navigate(`${pathname}/settings/testing`);
  }, [navigate, pathname]);

  const onNavigateToSettings = useCallback(() => {
    navigate(`${pathname}/settings`);
  }, [navigate, pathname]);

  const onClear = useCallback(() => {
    onChangeSelect('');
  }, [onChangeSelect]);

  const userServiceData = useMemo(() => {
    return userServiceAllData || {};
  }, [userServiceAllData]);

  const onClickNext = useCallback(() => {
    if (userServiceData?.variant === 'reserved') {
      onNavigateToTest();
    } else {
      onChangeCompletedStep(2, true);
    }
  }, [onChangeCompletedStep, onNavigateToTest, userServiceData]);

  return (
    <Col>
      <Form layout='vertical' form={form}>
        <Form.Item name='customer' style={{ marginBottom: 0 }}>
          <Row gutter={[20, 20]}>
            <Col>
              <Select
                onChange={onChangeSelect}
                placeholder={t('Chose SMS')}
                size='large'
                showSearch={true}
                allowClear={true}
                searchValue={searchValue}
                onSearch={onSearch}
                value={userService}
                onClear={onClear}
              >
                <Select.Option value='' disabled={true}>
                  {t('Chose SMS')}
                </Select.Option>
                {userServices.map((i) => (
                  <Select.Option key={i.id} value={i.id}>
                    {i.label || i.title}
                  </Select.Option>
                ))}
              </Select>
              {!!showNextButton && (
                <Row justify='end' style={{ marginTop: 20 }}>
                  <Col>
                    <MainButton onClick={onClickNext} type='default'>
                      {userServiceData?.variant === 'primary'
                        ? t('Next')
                        : t('Test SMS')}
                    </MainButton>
                  </Col>
                </Row>
              )}
            </Col>
            {!!showCreateButton && (
              <Col>
                <Button
                  type='default'
                  style={{ marginTop: 3 }}
                  onClick={onClickCreate}
                  disabled={createButtonDisabled}
                >
                  {t('Create new service')}
                </Button>
              </Col>
            )}
          </Row>
        </Form.Item>
      </Form>
      {!!showModal && (
        <NewSMSMethodModal
          onChangeUserService={onChangeUserService}
          onClose={onClose}
          open={showModal}
          fetchUserServices={fetchUserServices}
          title={searchValue}
        />
      )}
    </Col>
  );
};

export default SmsService;
