import { v4 as uuidv4 } from 'uuid';

class DataHelper {
  static isInputArrayType = (key) => {
    if (!key) return false;

    return key.includes('[') && key.includes(']');
  };

  static getSplitStringFromArray = (arr = [], splitSeparator = '\n', key = 'email') => {
    const cloned = structuredClone(arr);

    return (cloned || []).map(i => i[key]).join(splitSeparator);
  };

  static getPrefixDataForForm = (ipsWithPrefix = []) => {
    const data = {
      subnet: '',
      ips: [],
    };

    if (!Array.isArray(ipsWithPrefix)) return data;

    ipsWithPrefix.forEach(i => {
      const [main, sub] = i.split('/');
      data.subnet = sub;
      data.ips.push(main);
    });

    data.ips = data.ips.join(';');

    return data;
  };

  static prepareDataForPrefix = (ips, subnet) => {
    if (!ips) return '';
    const prepared = [];
    const ipsArray = ips.split(';');
    ipsArray.forEach(i => {
      prepared.push(`${i}/${subnet}`);
    });

    return prepared.join(';');
  };

  static getIPwWithSubnet = (values = {}) => {
    const ipsByUUID = {};
    const ips = [];
    try {
      Object.keys(values).forEach(key => {
        const [name, uuid] = key.split(':');
        const item = values[key];
        if (name.includes('ips_with_prefix') || name.includes('subnet')) {
          ipsByUUID[uuid] = {
            ...(
              ipsByUUID[uuid] || {}
            ),
            [name]: item
          };
        }
      });

      Object.keys(ipsByUUID).forEach(uuid => {
        const item = ipsByUUID[uuid];

        ips.push(`${item.ips_with_prefix}/${item.subnet}`);
      });
    } catch (e) {

    }

    return {
      ipsByUUID,
      ips_with_prefix: ips.join(';'),
    };
  };

  static parseStringIPsAndSubnet = (ips_with_prefix = '', dataByArray) => {
    if (!ips_with_prefix) return ips_with_prefix;

    const data = Array.isArray(dataByArray) ? dataByArray : ips_with_prefix.split(';');
    const preparedData = [];

    data.forEach(item => {
      const [ip, subnet] = item.split('/');
      preparedData.push({
        ip, subnet, id: uuidv4(),
      });
    });

    return preparedData;
  };

  static removeInputArrayCharacters = (key) => {
    if (!key) return '';

    return key.replace('[', '').replace(']', '');
  };

  static sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }

  static getDefaultImage = (item = {}) => {
    if (!item.images) {
      return '';
    }
    const [firstItem] = item.images;
    const defaultImage = item.images.find((i) => !!i.by_default);

    return defaultImage?.image || firstItem?.image;
  };

  static findFirstAvailableCollectTypeForShop(shop) {
    if (shop) {
      if (shop.is_click_reserve) {
        return 'is_click_reserve';
      }
      if (shop.is_click_collect) {
        return 'is_click_collect';
      }
      if (shop.is_online_shop) {
        return 'is_online_shop';
      }
    }
    return 'is_click_reserve';
  }

  static getStorePreparedData = (store) => {
    if (!store) return [];

    const result = [];

    Object.keys(store).forEach((shop) => {
      const item = store[shop];

      result.push({
        store: shop,
        brand: item.brand,
        items: item.items,
        order_type: item.order_type,
      });
    });

    return result;
  };

  static getStoreTotalData = (items) => {
    const result = [];

    if (!Array.isArray(items)) {
      return result;
    }

    items?.forEach((i) => {
      result.push({
        img: DataHelper.getPrimaryImage(i.images),
        price: DataHelper.getTotalPrice(i),
        title: i.title,
      });
    });

    return result;
  };

  static getPrimaryImage = (images) => {
    if (!images) return;

    const defaultImage = images.find((i) => i.by_default);

    if (defaultImage) {
      return defaultImage;
    }

    return images[0];
  };

  static getPrice = (price) => {
    return price / 100;
  };

  static getPriceWithComma = (price) => {
    if (!price) {
      return (0).toFixed(2).replace('.', ',');
    }
    return (price / 100).toFixed(2).replace('.', ',');
  };

  static formatedNumber = (num) => {
    try {
      return num.replace(/[.,]00$/, '');
    } catch (e) {
      return 0;
    }
  };

  static numberWithDot(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  static replaceDotToComma = (value) => {
    if (!value) {
      return value;
    }
    const v = value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    return v.replace ? v.replace('.', ',') : v;
  };

  static priceUserFriendlyView = (price, isCent = true) => {
    // return accounting.formatMoney(isCent ? DataHelper.getPrice(price) : price);
  };

  static getShopTotalPrice = (items, delivery) => {
    let price = delivery ? DataHelper.getPrice(delivery) : 0;
    items?.forEach((i) => {
      const currentPrice = DataHelper.getPrice(i.price || 0);
      price += currentPrice * i.count;
    });

    return DataHelper.priceUserFriendlyView(price, false);
  };

  static getOrderTypeTotalPrice = (items, delivery) => {
    let price = delivery ? DataHelper.getPrice(delivery) : 0;
    items?.forEach((i) => {
      const currentPrice = DataHelper.getPrice(i.price || 0);
      price += currentPrice * i.count;
    });

    return price;
  };

  static getTotalPrice = (item) => {
    const result =
      (item.count || 1) * DataHelper.getPrice(item.discuntPrice || item.price);

    return DataHelper.priceUserFriendlyView(result, false);
  };

  static getTotalDiscount = (item) => {
    const result = item.count * DataHelper.getPrice(item.discountPrice);
    return DataHelper.priceUserFriendlyView(result, false);
  };

  static getItemsTotalPrice = (items) => {
    let price = 0;

    items?.forEach((i) => {
      price += DataHelper.getTotalPrice(i);
    });

    return price;
  };

  static getOrderTotalPrice = (items, deliveryCosts) => {
    if (!items) {
      return 0;
    }

    let price = 0;
    let servicePrice = 0;

    Object.keys(items).forEach((store) => {
      const storeItem = items[store];
      if (deliveryCosts?.shops) {
        const deliveryInfo = deliveryCosts.shops[store];
        if (deliveryInfo?.delivery) {
          servicePrice += deliveryInfo.delivery || 0;
        }
      }
      storeItem?.items?.forEach((i) => {
        price += i.price * i.count || 1;
      });
    });

    return {
      price: DataHelper.priceUserFriendlyView(price),
      servicePrice: DataHelper.priceUserFriendlyView(servicePrice),
      totalPrice: DataHelper.priceUserFriendlyView(price + servicePrice),
    };
  };

  static getErrorByKey = (errors, key) => {
    if (!errors) return null;

    return errors[key];
  };

  static sortingByLetters = (
    lettersOrder = [],
    array = [],
    key = 'rilevanza_territoriale_evento',
  ) => {
    return array.sort((a, b) => {
      const aIndex = lettersOrder.indexOf(a[key]);
      const bIndex = lettersOrder.indexOf(b[key]);

      return aIndex - bIndex;
    });
  };

  static filterByKeys = (data = [], keys = ['title'], searchValue) => {
    return data.filter(item => {
      // return !!keys.find(key => {
      //   if (item[key]) {
      //     return item[key]?.toLowerCase().includes(searchValue.toLowerCase());
      //   }
      //
      //   return item;
      // });
    });
  };
}

export default DataHelper;
