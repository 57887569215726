import { SmallModal } from '../../modals';
import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ServicesDataKeys } from 'common/constants';
import UIHelper from 'services/helpers/UIHelper';
import ErrorHelper from 'services/helpers/ErrorHelper';
import { useParams } from 'react-router-dom';
import DomainsApi from 'libs/api/domains';
import { useServiceContext } from 'contexts/service';
import isValidDomain from 'is-valid-domain';
import ServerIPS from 'libs/api/serverIps';

const domainsApi = new DomainsApi();

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 28px;
`;

const CheckboxLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #000000;
`;

const StyledBtn = styled(Button)`
  width: 100%;
  height: 44px;
  font-weight: 500;
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.4;
          color: #fff !important;
          background: #2d70be !important;
        `
      : ''}
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledInput = styled(Input)`
  ${({ checked }) =>
    checked
      ? css`
          background: rgba(75, 179, 75, 0.15);
          border: 0.5px solid #4bb34b;
          border-radius: 8px;
          input {
            background: transparent;
          }
        `
      : ''}
  ${({ domainOccupied }) =>
    domainOccupied
      ? css`
          background: #faebeb;
          border: 0.5px solid #e64646;
          border-radius: 8px;
          input {
            background: transparent;
          }
        `
      : ''}
`;

const SuccessText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #327338;
`;

const api = new ServerIPS();

const NewDomain = ({
  title = 'New domain',
  onClose,
  open,
  domain = '',
  fetchDomains,
}) => {
  const params = useParams();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [ips, setIps] = useState([]);
  const [isCheckedDomain, setIsCheckedDomain] = useState(false);
  const [domainOccupied, setDomainOccupied] = useState(true);
  const [inProcess, setInProcess] = useState(false);
  const name = Form.useWatch('name', form);

  const { onChangeServiceData } = useServiceContext();

  const onConfirm = useCallback(
    async (values) => {
      const postData = {
        ...values,
        company_id: params.companyId,
        shared_type: values.dedicated ? 'dedicated' : 'shared',
      };

      if (values.dedicated) {
        postData.server_ip = '';
      }

      try {
        setInProcess(true);
        const { data } = await domainsApi.create({
          data: postData,
        });
        if (fetchDomains) {
          await fetchDomains();
        }
        onChangeServiceData(ServicesDataKeys.domain, data.domain.id);
        onChangeServiceData(ServicesDataKeys.domainAllData, data.domain);
        onClose();
        UIHelper.showSuccess(t('New domain successfully created!'));
      } catch (e) {
        UIHelper.showErrorFromAxios(e);
        form.setFields(
          ErrorHelper.getErrorsForForm(e?.response?.data?.errors || {}, {}),
        );
      } finally {
        setInProcess(false);
      }
    },
    [params, fetchDomains, onChangeServiceData, onClose, t, form],
  );

  const onClickCheckDomain = () => {
    if (!name) {
      form.submit();
    } else if (name && !isCheckedDomain) {
      //TODO DO API CALL
      setIsCheckedDomain(true);
      // setDomainOccupied(true);
      // form.setFields([
      //   {
      //     name: 'name',
      //     errors: [t('Domain is occupied, chose another name')],
      //   },
      // ]);
    } else {
      form.submit();
    }
  };

  useEffect(() => {
    if (!name) {
      setIsCheckedDomain(false);
      setDomainOccupied(false);
    }
  }, [name]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.list({
          params: {
            company_id: params.companyId,
          },
        });
        setIps(data.server_ips);
      } catch (e) {
      }
    })();
  }, [params]);

  const domainTypes = useMemo(() => {
    return [
      { id: 'notification', label: 'transactional' },
      { id: 'newsletter', label: 'newsletter' },
    ];
  }, []);

  const checkDomain = (_, value) => {
    if (isValidDomain(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Domain must be correct!'));
  };

  const domain_typeWatch = Form.useWatch('domain_type', form);
  const dedicated = Form.useWatch('dedicated', form);
  const policyLinkWatch = Form.useWatch('privacy_policy_link', form);
  const policyTextWatch = Form.useWatch('privacy_policy', form);

  const showServerIps = useMemo(() => {
    return !dedicated;
  }, [dedicated]);


  return (
    <SmallModal
      open={open}
      closable={false}
      footer={
        <Row gutter={12}>
          <Col span={12}>
            <StyledBtn onClick={onClose}>{t('Close')}</StyledBtn>
          </Col>
          <Col span={12}>
            <StyledBtn
              onClick={onClickCheckDomain}
              type={'primary'}
              loading={inProcess}
            >
              {isCheckedDomain ? t('Save') : t('Check')}
            </StyledBtn>
          </Col>
        </Row>
      }
    >
      <Form
        form={form}
        name='basic'
        initialValues={{
          dedicated: false,
          domain: domain,
          domain_type: 'notification',
          shared_type: 'shared',
          server_ip: '',
          dkim: true,
          tls_default: true,
          privacy_policy_link: '',
          privacy_policy: '', // TODO need change to real key
        }}
        onFinish={onConfirm}
        autoComplete='off'
        layout={'vertical'}
      >
        <Row>
          <Col span={24}>
            {/*<StyledTitle>{title}</StyledTitle>*/}
            <StyledTitle>{t('New domain')}</StyledTitle>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('Domain')}
              style={{
                marginBottom: isCheckedDomain ? 8 : 24,
              }}
              name='domain'
              rules={[
                {
                  required: true,
                  message: t('Please input domain name'),
                },
                {
                  validator: checkDomain,
                }
              ]}
            >
              <StyledInput
                domainOccupied={domainOccupied}
                checked={isCheckedDomain}
                allowClear
                size={'large'}
                placeholder={t('domain')}
              />
            </Form.Item>
            <Form.Item
              style={{
                marginBottom: isCheckedDomain ? 8 : 24,
              }}
              label={t('Domain type')}
              name='domain_type'
              rules={[]}
            >
              <Select
                style={{ maxWidth: 'unset' }}
                size={'large'}
                placeholder={t('Domain type')}
              >
                {domainTypes.map((i) => (
                  <Select.Option key={i.id}>{i.label}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            {domain_typeWatch !== 'notification' && (
              <>
                <Form.Item
                  style={{
                    marginBottom: isCheckedDomain ? 8 : 24,
                  }}
                  name='privacy_policy_link'
                >
                  <StyledInput
                    allowClear
                    size={'large'}
                    placeholder={t('Privacy policy link')}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    marginBottom: isCheckedDomain ? 8 : 24,
                  }}
                  name='privacy_policy'
                >
                  <Input.TextArea
                    allowClear
                    size={'large'}
                    placeholder={t('Privacy text')}
                  />
                </Form.Item>
              </>
            )}

            {isCheckedDomain && (
              <SuccessText>{t('Free domain name')}</SuccessText>
            )}
          </Col>
          <Col span={24}>
            <CheckboxContainer style={{ marginBottom: 12 }}>
              <CheckboxLabel>{'DKIM'}</CheckboxLabel>
              <Form.Item
                style={{ marginBottom: 0 }}
                name='dkim'
                valuePropName={'checked'}
              >
                <Checkbox />
              </Form.Item>
            </CheckboxContainer>
          </Col>
          <Col span={24}>
            <CheckboxContainer>
              <CheckboxLabel>{t('Dedicated IP')}</CheckboxLabel>
              <Form.Item
                style={{ marginBottom: 0 }}
                name='dedicated'
                valuePropName={'checked'}
              >
                <Checkbox />
              </Form.Item>
            </CheckboxContainer>
          </Col>
          <Col span={24}>
            {showServerIps && (
              <Form.Item
                style={{
                  marginTop: 20,
                }}
                label={t('Server IP')}
                name='server_ip'
                rules={[]}
              >
                <Select
                  style={{ maxWidth: 'unset' }}
                  size={'large'}
                  placeholder={t('Server IP')}
                >
                  <Select.Option key={''} value={''}>{t('Random')}</Select.Option>

                  {ips.map((i) => (
                    <Select.Option key={i.public_ip} value={i.public_ip}>{i.public_ip} {i.label ? `(${i.label})` : ''}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </SmallModal>
  );
};

export default NewDomain;
