import {
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  Form,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useServiceContext } from 'contexts/service';
import { useCreateForm } from 'hooks/use-create-form';
import { MainButton } from 'components/buttons/main';
import DomainsApi from 'libs/api/domains';
import { useTranslation } from 'react-i18next';
import NewDomain from 'components/myModals/newDomain';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ServicesDataKeys } from 'common/constants';
import { domainAdapter } from 'adapters/domain.adapter';
import UIHelper from 'services/helpers/UIHelper';
import ErrorHelper from 'services/helpers/ErrorHelper';

const domainsApi = new DomainsApi();

export const DNSConfigsView = ({
  data = {},
  title,
  hideTitle = false,
  maxWidth = 300,
  loading,
}) => {
  const { t } = useTranslation();
  return (
    <Row gutter={[8, 8]} style={{ maxWidth, marginTop: 20 }}>
      <Col span={24}>
        {!hideTitle && (
          <Typography.Title level={5} style={{ margin: 0 }} type='strong'>
            {title || t('You should configure your DNS')}
          </Typography.Title>
        )}
      </Col>
      {Object.keys(data).length ? (
        Object.keys(data).map((key) => {
          const text = data[key];

          return (
            <Col key={key}>
              <Row justify='space-between' wrap={true} gutter={[8, 8]}>
                <Col>
                  <Typography.Text type='strong'>{key}</Typography.Text>
                </Col>
                <Col>
                  <Typography.Text type='warning'>{text}</Typography.Text>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>
            </Col>
          );
        })
      ) : (
        <>{loading ? <Spin /> : <Empty />}</>
      )}
    </Row>
  );
};

const EmailService = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [domains, setDomains] = useState([]);
  const [inProcess, setInProcess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [DNSConfigs, setDNSConfigs] = useState({});

  const [skipCheckDNSConfigs, setSkipCheckDNSConfigs] = useState(false);
  const [overWriteDNSConfig, setOverWriteDNSConfig] = useState(false);
  const [showSkipCheckDNS, setShowSkipCheckDNS] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();

  const {
    serviceData: { domain },
    onChangeServiceData,
  } = useServiceContext();

  const onChangeDomain = useCallback(
    (value) => {
      onChangeServiceData(ServicesDataKeys.domain, value);
      const f = domains.find((i) => i.id === +value);
      onChangeServiceData(ServicesDataKeys.domainAllData, f);
      setSkipCheckDNSConfigs(false);
      setShowSkipCheckDNS(false);
      setDNSConfigs({});
    },
    [onChangeServiceData, domains],
  );

  const { searchValue, onSearch, onChangeSelect, setSearchValue } =
    useCreateForm(onChangeDomain);

  const onClose = useCallback(() => {
    setShowModal(false);

    if (setSearchValue) {
      setSearchValue('');
    }
  }, [setSearchValue]);

  const fetchDomains = useCallback(async () => {
    const { data } = await domainsApi.list({
      params: {
        company_id: params.companyId,
      },
    });
    setDomains(domainAdapter(data.domains));
  }, [params]);

  useEffect(() => {
    (async () => {
      try {
        await fetchDomains();
      } catch (e) {}
    })();
    //eslint-disable-next-line
  }, []);

  const showNextButton = useMemo(() => {
    return !!domain;
  }, [domain]);

  const onNext = useCallback(async () => {
    if (overWriteDNSConfig) {
      return navigate(`${pathname}/settings`);
    }
    if (skipCheckDNSConfigs) {
      return navigate(
        `${pathname}/settings?skipTestMethod=${skipCheckDNSConfigs}`,
      );
    }
    try {
      setInProcess(true);
      const { data } = await domainsApi.get(`${domain}/dns_to_configure`);
      const isOK =
        data.success && (!data.body || !Object.keys(data.body).length);
      if (isOK) {
        navigate(`${pathname}/settings`);
      } else {
        setDNSConfigs(data);
        setShowSkipCheckDNS(true);
      }
    } catch (e) {
    } finally {
      setInProcess(false);
    }
  }, [pathname, navigate, domain, skipCheckDNSConfigs, overWriteDNSConfig]);

  const showCreateButton = useMemo(() => {
    return !domain;
  }, [domain]);

  const onClickCreate = useCallback(() => {
    setShowModal((s) => !s);
  }, []);

  const onClear = useCallback(() => {
    onChangeSelect('');
  }, [onChangeSelect]);

  const createButtonDisabled = useMemo(() => {
    return !searchValue || !!domain;
  }, [domain, searchValue]);

  console.log('domain', domain);

  return (
    <Col>
      <Form layout='vertical' form={form}>
        <Form.Item name='customer' style={{ marginBottom: 20 }}>
          <Row gutter={[20, 20]}>
            <Col>
              <Select
                onChange={onChangeSelect}
                placeholder={t('Chose domain')}
                size='large'
                showSearch={true}
                allowClear={true}
                // disabled={disabled}
                searchValue={searchValue}
                onSearch={onSearch}
                value={domain}
                onClear={onClear}
              >
                <Select.Option value={''}>{t('Chose domain')}</Select.Option>
                {domains.map((i) => (
                  <Select.Option key={i.id} value={i.id}>
                    {i.label}
                  </Select.Option>
                ))}
              </Select>
              {showSkipCheckDNS && <DNSConfigsView data={DNSConfigs?.body} />}
              {showSkipCheckDNS && (
                <Row
                  justify='end'
                  gutter={[8, 8]}
                  style={{
                    marginTop: 20,
                    flexDirection: 'column',
                    alignItems: 'end',
                  }}
                >
                  <Col>
                    <Checkbox
                      checked={skipCheckDNSConfigs}
                      onChange={(ev, c) => {
                        setSkipCheckDNSConfigs(ev.target.checked);
                        setOverWriteDNSConfig(false);
                      }}
                    >
                      {t('Skip DNS errors')}
                    </Checkbox>
                  </Col>
                  <Col>
                    <Checkbox
                      checked={overWriteDNSConfig}
                      onChange={(ev, c) => {
                        setOverWriteDNSConfig(ev.target.checked);
                        setSkipCheckDNSConfigs(false);
                      }}
                    >
                      {t('Overwrite DNS config')}
                    </Checkbox>
                  </Col>
                </Row>
              )}
              {!!showNextButton && (
                <Row justify='end' style={{ marginTop: 20 }}>
                  <Col>
                    <MainButton
                      loading={inProcess}
                      type='default'
                      onClick={onNext}
                    >
                      {t('Next')}
                      {/*{!skipCheckDNSConfigs*/}
                      {/*  ? showSkipCheckDNS*/}
                      {/*    ? t('Check again')*/}
                      {/*    : t('Check')*/}
                      {/*  : t('Next')}*/}
                    </MainButton>
                  </Col>
                </Row>
              )}
            </Col>
            {!!showCreateButton && (
              <Col>
                <Button
                  type='default'
                  style={{ marginTop: 3 }}
                  onClick={onClickCreate}
                  disabled={createButtonDisabled}
                >
                  {t('Create new domain')}
                </Button>
              </Col>
            )}
          </Row>
        </Form.Item>
      </Form>
      {!!showModal && (
        <NewDomain
          domain={searchValue}
          open={showModal}
          title={searchValue}
          onClose={onClose}
          fetchDomains={fetchDomains}
        />
      )}
    </Col>
  );
};

export default EmailService;
