import { useState, useCallback, useEffect, useMemo } from 'react';
import UsersApi from 'libs/api/users';
import { customerAdapter } from 'adapters/customer.adapter';
import { useTranslation } from 'react-i18next';
import { StyledTableHeader, StyledTableText } from 'components/titles';
import { Button, Col, Row, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, ScissorOutlined } from '@ant-design/icons';
import UIHelper from 'services/helpers/UIHelper';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CompaniesApi from 'libs/api/companies';

const usersApi = new UsersApi();
const companiesApi = new CompaniesApi();

export const useCustomers = (autoFetch = false, companyId) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [usersAllData, setUsersAllData] = useState([]);
  const [pagination, setPagination] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const fetchData = useCallback(
    async (params = {}) => {
      setLoading(true);
      try {
        const preparedParams = params;
        if (companyId) {
          preparedParams.params = {
            ...(preparedParams.params || {}),
            company_id: companyId,
          };
        }
        const { data } = await usersApi.list(params);
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 2000);
        });
        setData(customerAdapter(data.users));
        setPagination(data.pagination);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    [companyId],
  );

  const fetchUsersAllData = useCallback(async () => {
    try {
      const { data } = await usersApi.list({
        params: {
          without_company_id: companyId,
        },
      });
      setUsersAllData(data.users);
    } catch (e) {
    } finally {
    }
  }, [companyId]);

  const onDelete = useCallback(
    async (id) => {
      try {
        setLoading(true);
        await usersApi.destroy(id);
        fetchUsersAllData();
        fetchData();
      } catch (e) {
        UIHelper.showErrorFromAxios(e);
      } finally {
      }
    },
    [fetchData, fetchUsersAllData],
  );

  const onDetach = useCallback(
    async (id) => {
      try {
        setLoading(true);
        await companiesApi.unlinkUser(companyId, {
          data: {
            user_id: id,
          },
        });
        UIHelper.showSuccess(t('Successfully detached'));
        await fetchData();
        await fetchUsersAllData();
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    [fetchData, companyId, t],
  );

  useEffect(() => {
    if (autoFetch) {
      (async () => {
        try {
          await fetchData();
          await fetchUsersAllData();
        } catch (e) {}
      })();
    }
  }, [autoFetch, fetchData, fetchUsersAllData]);

  const columns = useMemo(() => {
    return [
      // {
      //   width: 100,
      //   title: <StyledTableHeader>ID</StyledTableHeader>,
      //   dataIndex: 'id',
      //   render: (data) => <StyledTableText>{data}</StyledTableText>,
      // },
      {
        width: 200,
        title: <StyledTableHeader>Email</StyledTableHeader>,
        dataIndex: 'email',
        // render: (data, row) => row.is_show_credential ? <StyledTableText>{data}</StyledTableText> : <></>,
        render: (data, row) => <StyledTableText>{data}</StyledTableText>,
      },
      {
        width: 200,
        title: <StyledTableHeader>Token</StyledTableHeader>,
        dataIndex: 'jwt_token',
        // render: (data, row) => row.is_show_credential ? <StyledTableText>{data}</StyledTableText> : <></>,
        render: (data, row) => <StyledTableText>{data}</StyledTableText>,
      },
      // {
      //   width: 120,
      //   title: <StyledTableHeader>{t('User name')}</StyledTableHeader>,
      //   dataIndex: 'username',
      //   render: (data) => <StyledTableText>{data}</StyledTableText>,
      // },
      // {
      //   width: 100,
      //   title: <StyledTableHeader>{t('Role')}</StyledTableHeader>,
      //   dataIndex: 'role',
      //   render: (data) => <StyledTableText>{data}</StyledTableText>,
      // },
      {
        width: 150,
        title: <StyledTableHeader>{t('Actions')}</StyledTableHeader>,
        dataIndex: '__',
        render: (data, row) => (
          <Row gutter={[8, 8]} style={{ padding: '0 4px' }} justify='center'>
            <Col>
              <Link to={`${pathname}/${row.id}`}>
                <Button icon={<EditOutlined />} />
              </Link>
            </Col>
            <Col>
              <Popconfirm
                title={t('Delete the access')}
                description={`${t('Are you sure to delete this access')} ?`}
                onConfirm={() => {
                  onDelete(row.id);
                }}
                okText={t('Yes')}
                cancelText={t('No')}
              >
                <Button danger={true} icon={<DeleteOutlined />} />
              </Popconfirm>
            </Col>
            <Col>
              <Popconfirm
                title={t('Detach the access')}
                description={`${t('Are you sure to detach this access')} ?`}
                onConfirm={() => {
                  onDetach(row.id);
                }}
                okText={t('Yes')}
                cancelText={t('No')}
              >
                <Button danger={true} icon={<ScissorOutlined />} />
              </Popconfirm>
            </Col>
          </Row>
        ),
      },
    ];
  }, [t, onDelete, pathname]);

  const onClickCreate = useCallback(() => {
    navigate(`${pathname}/new`);
  }, [navigate, pathname]);

  // const preparedUsersAllData = useMemo(() => {
  //   return usersAllData.filter((user) => !data.find((i) => i.id === user.id));
  // }, [usersAllData, data]);

  return {
    fetchData,
    loading,
    pagination,
    data,
    columns,
    onClickCreate,
    usersAllData: usersAllData,
    fetchUsersAllData,
  };
};
