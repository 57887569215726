import axios from 'axios';
import UIHelper from 'services/helpers/UIHelper';
// import AuthService from '../services/Auth';

export default function configureAxios() {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status >= 500) {
        UIHelper.showError();
      }
      return Promise.reject(error);
    },
  );
  // axios.interceptors.request.use((config) => {
  //   config.paramsSerializer = (params) => {
  //     // // Qs is already included in the Axios package
  //     // return Qs.stringify(params, {
  //     //   arrayFormat: 'brackets',
  //     //   encode: false,
  //     // });
  //   };
  //
  //   return config;
  // });
}
