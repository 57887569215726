import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  NewsletterTypes,
  RouteCodes,
  ServicesDataKeys,
} from 'common/constants';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useServiceContext } from 'contexts/service';
import DomainEmailsApi from 'libs/api/domain_emails';
import UIHelper from 'services/helpers/UIHelper';
import ErrorHelper from 'services/helpers/ErrorHelper';
import StepButton from 'components/buttons/step-button';
import DataHelper from 'services/helpers/DataHelper';
import SmtpIPs from 'components/modules/services/create/settings/SmtpIPs';
import UserServices from 'libs/api/user_services';
import FormHelper from 'services/helpers/FormHelper';
import { useCreateForm } from 'hooks/use-create-form';
import NewSMSBackupMethodModal from 'components/myModals/newSMSBackupMethodModal';

const domainEmailsApi = new DomainEmailsApi();
const userServicesApi = new UserServices();

const MainSettings = () => {
  const { t } = useTranslation();
  const params = useParams();
  const services = useMemo(() => {
    return [];
  }, []);

  const navigate = useNavigate();

  const { serviceData } = useServiceContext();

  useEffect(() => {
    if (!serviceData[ServicesDataKeys.domain]) {
      navigate(
        RouteCodes.services.path.replace(':companyId', params.companyId),
      );
    }
  }, [params, serviceData, navigate]);

  return (
    <>
      <Form>
        <Form.Item>
          <Checkbox>{t('Backup')}</Checkbox>
        </Form.Item>
        <Form.Item>
          <Select placeholder={t('Choose service')}>
            {services.map((i) => (
              <Select.Option key={i.id}>{i.title}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type='default'>{t('Next step')}</Button>
        </Form.Item>
      </Form>
    </>
  );
};

const SMSSettings = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const params = useParams();
  const [showModal, setShowModal] = useState(false);
  const [inProcess, setInProcess] = useState(false);
  const [userServices, setUserServices] = useState([]);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onClickCreate = useCallback(() => {
    setShowModal((s) => !s);
  }, []);

  const onNavigateToTest = useCallback(() => {
    navigate(`${pathname}/settings/testing`);
  }, [navigate, pathname]);

  const {
    serviceData: {
      userService,
      userServiceAllData,
      userServiceReserved,
      userServiceReservedAllData,
    },
    onChangeServiceData,
  } = useServiceContext();

  const fetchUserServices = useCallback(async () => {
    try {
      const { data } = await userServicesApi.list({
        params: {
          by_variant: 'reserved',
          company_id: params?.companyId,
        },
      });
      setUserServices(data.user_services);
    } catch (e) {}
  }, [params]);

  const onUpdate = useCallback(
    async (values) => {
      try {
        setInProcess(true);
        const { data } = await userServicesApi.put(userService, {
          data: {
            reserved_service_id: values.backup
              ? // ? (values.reserved_service_id)
                userServiceReservedAllData?.id
              : '-1',
          },
        });
        onChangeServiceData(
          ServicesDataKeys.userServiceAllData,
          data.user_service || userServiceAllData,
        );
        if (!values.backup) {
          onChangeServiceData(ServicesDataKeys.userServiceReserved, '');
          onChangeServiceData(ServicesDataKeys.userServiceReservedAllData, {});
        }
        onNavigateToTest();
      } catch (e) {
        UIHelper.showErrorFromAxios(e);
        form.setFields(
          ErrorHelper.getErrorsForForm(e?.response?.data?.errors || {}, {}),
        );
      } finally {
        setInProcess(false);
      }
    },
    [
      userService,
      form,
      onNavigateToTest,
      userServiceAllData,
      onChangeServiceData,
      userServiceReservedAllData,
    ],
  );

  console.log('userServiceReservedAllData', userServiceReserved, '--',  userServiceReservedAllData);

  useEffect(() => {
    (async () => {
      await fetchUserServices();
    })();
    // eslint-disable-next-line
  }, []);

  const onFinish = useCallback(
    (values) => {
      // TODO ask Boris about this logic
      return onUpdate(values);
    },
    [onUpdate],
  );

  const backupWatch = Form.useWatch('backup', form);

  useEffect(() => {
    if (!backupWatch) {
      form.setFields([
        {
          name: 'reserved_service_id',
          errors: [],
        },
      ]);
    }
  }, [backupWatch, form]);

  const onChangeUserService = useCallback(
    (value) => {
      onChangeServiceData(ServicesDataKeys.userServiceReserved, value);
      const f = userServices.find((i) => i.id === +value);
      onChangeServiceData(ServicesDataKeys.userServiceReservedAllData, f);
    },
    [onChangeServiceData, userServices],
  );

  const changeAfterSelect = useCallback(
    (value) => {
      onChangeUserService(value);
      form.setFields([
        {
          name: 'reserved_service_id',
          value,
          errors: [],
        },
      ]);
    },
    [onChangeUserService, form],
  );

  const { searchValue, onSearch, onChangeSelect, setSearchValue } =
    useCreateForm(changeAfterSelect);

  const onClear = useCallback(() => {
    onChangeSelect('');
  }, [onChangeSelect]);

  const showCreateButton = useMemo(() => {
    return !userServiceReserved;
  }, [userServiceReserved]);

  const createButtonDisabled = useMemo(() => {
    return !searchValue || !!userServiceReserved;
  }, [userServiceReserved, searchValue]);

  const onClose = useCallback(() => {
    setShowModal(false);

    if (setSearchValue) {
      setSearchValue('');
    }

    if (fetchUserServices) {
      fetchUserServices();
    }
  }, [setSearchValue, fetchUserServices]);

  return (
    <>
      <Form
        form={form}
        initialValues={{
          backup: !!userServiceAllData?.reserved_service?.id,
          reserved_service_id:
            userServiceAllData?.reserved_service?.id ||
            userServiceReservedAllData?.id ||
            userServiceReserved ||
            '',
        }}
        onFinish={onFinish}
      >
        <Row justify='space-between' style={{ maxWidth: 300 }}>
          <Col>{t('Backup')}</Col>
          <Col>
            <Form.Item name='backup' valuePropName={'checked'}>
              <Checkbox disabled={false} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name='reserved_service_id'
          rules={[
            {
              required: !!backupWatch,
            },
          ]}
        >
          <Row gutter={[20, 20]}>
            <Col>
              <Select
                onChange={onChangeSelect}
                disabled={!backupWatch}
                placeholder={t('Choose service')}
                size='large'
                showSearch={true}
                allowClear={true}
                searchValue={searchValue}
                onSearch={onSearch}
                value={userServiceReserved}
                onClear={onClear}
              >
                <Select.Option key='' value={''} disabled={true}>{t('Choose service')}</Select.Option>
                {userServices.map((i) => (
                  <Select.Option key={i.id} value={i.id}>
                    {i.title}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            {!!showCreateButton && (
              <Col>
                <Button
                  type='default'
                  style={{ marginTop: 3 }}
                  onClick={onClickCreate}
                  disabled={createButtonDisabled}
                >
                  {t('Create backup service')}
                </Button>
              </Col>
            )}
          </Row>
        </Form.Item>
        <Row justify='end' style={{ marginTop: 20, maxWidth: 300 }}>
          <Col>
            <Form.Item>
              <Button loading={inProcess} htmlType='submit' type='default'>
                {t('Test SMS')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {!!showModal && (
        <NewSMSBackupMethodModal
          title={searchValue}
          onClose={onClose}
          open={showModal}
        />
      )}
    </>
  );
};

export const EmailSettings = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { pathname } = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const skipTestMethod = searchParams.get('skipTestMethod');

  const { serviceData, onChangeServiceData } = useServiceContext();

  const [loading, setLoading] = useState(false);

  const onClickGoToTest = useCallback(() => {
    navigate(`${pathname}/testing${skipTestMethod ? `?skipTestMethod=${skipTestMethod}` : ''}`);
  }, [pathname, navigate, skipTestMethod]);

  useEffect(() => {
    if (!serviceData[ServicesDataKeys.domain]) {
      navigate(
        RouteCodes.services.path.replace(':companyId', params.companyId),
      );
    }
  }, [params, serviceData, navigate]);

  const onFinish = useCallback(
    async (values) => {
      const isEdit =
        serviceData[ServicesDataKeys.domainEmail] &&
        !!serviceData[ServicesDataKeys.domainEmail].id;
      try {
        setLoading(true);
        const { ips_with_prefix, subnet, enableSMTP, ...rest } = values;
        const requestData = {
          domain_id: serviceData[ServicesDataKeys.domain],
          set_default_ip: enableSMTP ? '0' : '1',
          email: rest.email,
          email_alias: rest.email_alias,
          day_limit: rest.day_limit,
          month_limit: rest.month_limit,
        };

        if (enableSMTP || isEdit) {
          const { ips_with_prefix } = DataHelper.getIPwWithSubnet(rest);
          requestData.ips_with_prefix = ips_with_prefix;
        }
        let domainData = {};
        if (isEdit) {
          const { data } = await domainEmailsApi.put(
            serviceData[ServicesDataKeys.domainEmail].id,
            {
              data: requestData,
            },
          );
          domainData = data;
        } else {
          const { data } = await domainEmailsApi.create({
            data: requestData,
          });
          domainData = data;
        }
        onChangeServiceData(ServicesDataKeys.domainEmail, {
          ...(domainData.domain_email || {}),
          ...requestData,
          is_default_ip:
            enableSMTP ||
            (isEdit &&
              (domainData.domain_email?.is_default_ip ||
                serviceData[ServicesDataKeys.domainEmail]?.is_default_ip)),
          email: domainData.domain_email?.email,
        });
        onClickGoToTest();
      } catch (e) {
        console.log('e', e);
        UIHelper.showErrorFromAxios(e);
        form.setFields(
          ErrorHelper.getErrorsForForm(e?.response?.data?.errors || {}, {}),
        );
      } finally {
        setLoading(false);
      }
    },
    [serviceData, onChangeServiceData, form, onClickGoToTest],
  );

  const enableSMTP = Form.useWatch('enableSMTP', form);

  const defaultEnable =
    serviceData[ServicesDataKeys.domainEmail] &&
    !!serviceData[ServicesDataKeys.domainEmail].id;

  const showEnableButton = useMemo(() => {
    return !serviceData[ServicesDataKeys.domainEmail]?.id;
  }, [serviceData]);

  return (
    <>
      <Form
        initialValues={{
          email: serviceData[ServicesDataKeys.domainEmail]
            ? UIHelper.getDomainEmailWithoutPrefix(
                serviceData[ServicesDataKeys.domainEmail].email,
              )
            : '',
          email_alias: serviceData[ServicesDataKeys.domainEmail]
            ? serviceData[ServicesDataKeys.domainEmail].email_alias
            : '',
          enableSMTP: defaultEnable,
          ips_with_prefix: serviceData[ServicesDataKeys.domainEmail] ? '' : '',
          subnet: serviceData[ServicesDataKeys.domainEmail] ? '' : '',
          day_limit: serviceData[ServicesDataKeys.domainEmail]
            ? serviceData[ServicesDataKeys.domainEmail].day_limit
            : '',
          month_limit: serviceData[ServicesDataKeys.domainEmail]
            ? serviceData[ServicesDataKeys.domainEmail].month_limit
            : '',
        }}
        onFinish={onFinish}
        form={form}
        labelCol={{ xs: { offset: 40 } }}
        onFieldsChange={FormHelper.resetFormsFieldErrors(form, [
          'day_limit',
          'month_limit',
        ])}
      >
        <Row gutter={[20, 20]} align='middle'>
          <Col>
            <Form.Item
              rules={[
                {
                  required: true,
                },
              ]}
              name='email'
              style={{ marginBottom: 0 }}
            >
              <Input size='large' placeholder='email' />
            </Form.Item>
          </Col>
          <Col>
            <Typography.Text>
              @{serviceData[ServicesDataKeys.domainAllData]?.domain}
            </Typography.Text>
          </Col>
        </Row>

        <Form.Item
          name='email_alias'
          style={{ marginBottom: 40, marginTop: 40 }}
        >
          <Input size='large' placeholder='alias' />
        </Form.Item>
        <Row gutter={[12, 12]} style={{ marginBottom: 10 }}>
          <Col>
            <Form.Item
              style={{ minWidth: 'unset', width: 142 }}
              name='day_limit'
            >
              <Input
                type='number'
                style={{ minWidth: 'unset', width: 142 }}
                size='large'
                placeholder={t('max/day')}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name='month_limit'>
              <Input
                type='number'
                style={{ minWidth: 'unset', width: 142 }}
                size='large'
                placeholder={t('max/month')}
              />
            </Form.Item>
          </Col>
        </Row>
        {showEnableButton && (
          <Row justify='space-between' style={{ maxWidth: 300 }}>
            <Col>{t('Enable SMTP')}</Col>
            <Col>
              <Form.Item name='enableSMTP' valuePropName={'checked'}>
                <Checkbox
                  disabled={
                    serviceData[ServicesDataKeys.domainEmail] &&
                    serviceData[ServicesDataKeys.domainEmail].id
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        {(!showEnableButton || enableSMTP) && (
          <>
            <SmtpIPs
              form={form}
              dataByArray={
                serviceData[ServicesDataKeys.domainEmail]?.ips_with_prefix
              }
              ips_with_prefix={
                serviceData[ServicesDataKeys.domainEmail] &&
                serviceData[ServicesDataKeys.domainEmail]?.ips_with_prefix
              }
            />
            {/*<Form.Item*/}
            {/*  rules={[*/}
            {/*    {*/}
            {/*      required: true,*/}
            {/*      message: t('IPs with prefix is required'),*/}
            {/*    },*/}
            {/*  ]}*/}
            {/*  name='ips_with_prefix'*/}
            {/*  style={{ marginBottom: 24 }}*/}
            {/*>*/}
            {/*  <Input size='large' placeholder='IP' />*/}
            {/*</Form.Item>*/}
            {/*<Form.Item*/}
            {/*  rules={[*/}
            {/*    {*/}
            {/*      required: true,*/}
            {/*    },*/}
            {/*  ]}*/}
            {/*  name='subnet'*/}
            {/*  style={{ marginBottom: 24 }}*/}
            {/*>*/}
            {/*  <Select*/}
            {/*    size='large'*/}
            {/*    placeholder='subnet'*/}
            {/*  >*/}
            {/*    <Select.Option disabled={true} value={''}>{t('subnet')}</Select.Option>*/}
            {/*    {subNets.map(i => (*/}
            {/*      <Select.Option key={i.value} value={i.value}>{i.label}</Select.Option>*/}
            {/*    ))}*/}
            {/*  </Select>*/}
            {/*  /!*<Input size='large' type='number' placeholder='subnet' />*!/*/}
            {/*</Form.Item>*/}
            {/*<ShowServerDetails*/}
            {/*  ips={`${ipsWatch}${subnetWatch ? `/${subnetWatch}` : ''}`}*/}
            {/*/>*/}
          </>
        )}
        <Form.Item>
          <Row justify='end' style={{ maxWidth: 300 }}>
            <Col>
              <StepButton
                size='middle'
                type='primary'
                htmlType='submit'
                style={{ width: 150 }}
                loading={loading}
              >
                {serviceData[ServicesDataKeys.domainEmail] &&
                serviceData[ServicesDataKeys.domainEmail].id
                  ? t('Next')
                  : t('Create')}
              </StepButton>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

const Setting = ({ variant = NewsletterTypes.email }) => {
  const content = useMemo(() => {
    switch (variant) {
      case NewsletterTypes.email:
        return <EmailSettings />;
      case NewsletterTypes.sms:
        return <SMSSettings />;
      default:
        return <MainSettings />;
    }
  }, [variant]);

  return <div>{content}</div>;
};

export default Setting;
