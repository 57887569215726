import PrimaryLayout from 'components/layout';
import Services from 'components/modules/services';

const ServicesPage = () => {
  return (
    <PrimaryLayout>
      <Services />
    </PrimaryLayout>
  );
};

export default ServicesPage;
