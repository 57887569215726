import { Button, Col, Divider, Form, Input, Row, Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NewsletterTypes, ServicesDataKeys } from 'common/constants';
import DomainEmailsApi from 'libs/api/domain_emails';
import { useServiceContext } from 'contexts/service';
import TransactionalsApi from 'libs/api/transactionals';
import UIHelper from 'services/helpers/UIHelper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SMSApi from 'libs/api/sms';
import { StyledTableHeader, StyledTableText } from 'components/titles';

const domainEmailsApi = new DomainEmailsApi();
const transactionalsApi = new TransactionalsApi();
const smsApi = new SMSApi();

const ServiceTestForm = ({ variant = NewsletterTypes.email }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { serviceData, resetState } = useServiceContext();
  const [loading, setLoading] = useState(false);
  const [backupTestInProcess, setBackupTestInProcess] = useState(false);
  const [domainConfig, setDomainConfig] = useState([]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const skipTestMethod = searchParams.get('skipTestMethod');

  const goHome = useCallback(() => {
    navigate('/');
    resetState();
  }, [navigate, resetState]);

  const dataWatch = Form.useWatch('data', form);

  useEffect(() => {
    if (
      serviceData[ServicesDataKeys.newsletter] &&
      serviceData[ServicesDataKeys.newsletter] === NewsletterTypes.email
    ) {
      (async () => {
        try {
          const { data } = await domainEmailsApi.get(
            `${serviceData[ServicesDataKeys.domainEmail]?.id}/show_config`,
            {
              params: {
                domain_id: serviceData[ServicesDataKeys.domainAllData]?.id,
              },
            },
          );
          setDomainConfig([data.config]);
        } catch (e) {}
      })();
    }
  }, [serviceData]);

  useEffect(() => {
    if (
      !serviceData[ServicesDataKeys.newsletter] &&
      !serviceData[ServicesDataKeys.userService] &&
      !serviceData[ServicesDataKeys.domain] &&
      !(
        serviceData[ServicesDataKeys.userServiceAllData] &&
        !!serviceData[ServicesDataKeys.userServiceAllData].id
      ) &&
      !(
        serviceData[ServicesDataKeys.domainAllData] &&
        !!serviceData[ServicesDataKeys.domainAllData].id
      )
    ) {
      navigate('/');
    }
  }, [serviceData, navigate]);

  const allSMSData = useMemo(() => {
    return serviceData[ServicesDataKeys.userServiceAllData];
  }, [serviceData]);

  const texts = useMemo(() => {
    const result = {
      buttonText: '',
      placeholder: '',
    };

    switch (variant) {
      case NewsletterTypes.email:
        // result.buttonText = t('Test of mail method');
        result.buttonText = t('Send');
        result.placeholder = t('Email');
        break;
      case NewsletterTypes.sms:
        result.buttonText = t('Send');
          // variant === NewsletterTypes.sms && !!allSMSData?.reserved_service?.id
          //   ? t('Test main sms service')
          //   : t('Test of sms method');
        result.placeholder = t('Phone');
        break;
      default:
        break;
    }

    return result;
  }, [t, variant, allSMSData]);

  const onSubmit = useCallback(
    async (values) => {
      try {
        setLoading(true);
        if (variant === NewsletterTypes.email) {
          const { data } = await transactionalsApi.sendTest({
            data: {
              from_email: serviceData[ServicesDataKeys.domainEmail]?.email,
              send_to: values.data,
            },
          });
          UIHelper.showSuccess('Email successfully sent');
          setTimeout(() => {
            navigate('/');
          }, 500);
        }
        if (variant === NewsletterTypes.sms) {
          // TODO need functional in backend
          await smsApi.sendTestSMS({
            data: {
              send_to: values.data,
              message: 'Test sms method',
              user_service_id:
                serviceData[ServicesDataKeys.userServiceAllData]?.id,
            },
          });
          UIHelper.showSuccess('SMS successfully sent');
        }
      } catch (e) {
        UIHelper.showError();
        UIHelper.showErrorFromAxios(e);
      } finally {
        setLoading(false);
      }
    },
    [serviceData, navigate, variant],
  );

  const onClickTestBackup = useCallback(async () => {
    try {
      setBackupTestInProcess(true);
      await smsApi.sendTestSMS({
        data: {
          send_to: dataWatch,
          message: 'Test sms method',
          user_service_id: allSMSData?.reserved_service?.id,
        },
      });
      UIHelper.showSuccess('SMS successfully sent');
    } catch (e) {
      UIHelper.showError();
      UIHelper.showErrorFromAxios(e);
    } finally {
      setBackupTestInProcess(false);
    }
  }, [dataWatch, allSMSData]);

  const showTable = useMemo(() => {
    return (
      serviceData[ServicesDataKeys.newsletter] === NewsletterTypes.email &&
      !!serviceData[ServicesDataKeys.domainEmail]?.id &&
      !!serviceData[ServicesDataKeys.domainEmail]?.is_default_ip
    );
  }, [serviceData]);

  const SMSBackupTestButton = useMemo(() => {
    if (variant === NewsletterTypes.sms && !!allSMSData?.reserved_service?.id) {
      return (
        <>
          <Col>
            <Form.Item>
              <Tooltip
                placement='right'
                title={allSMSData?.reserved_service?.title}
              >
                <Button
                  type='default'
                  onClick={onClickTestBackup}
                  loading={backupTestInProcess}
                  disabled={loading}
                >
                  {/*{t('Test backup service')}*/}
                  {t('Send from backup service')}
                </Button>
              </Tooltip>
            </Form.Item>
          </Col>
        </>
      );
    }

    return null;
  }, [variant, t, allSMSData, onClickTestBackup, backupTestInProcess, loading]);

  const columns = useMemo(() => {
    return [
      {
        width: 180,
        title: <StyledTableHeader>{t('Host')}</StyledTableHeader>,
        dataIndex: 'host',
        render: (data) => <StyledTableText>{data}</StyledTableText>,
      },
      {
        width: 100,
        title: <StyledTableHeader>{t('Port')}</StyledTableHeader>,
        dataIndex: 'port',
        render: (data) => <StyledTableText>{data}</StyledTableText>,
      },
      {
        width: 210,
        title: <StyledTableHeader>{t('User name')}</StyledTableHeader>,
        dataIndex: 'user_name',
        render: (data) => <StyledTableText>{data}</StyledTableText>,
      },
      {
        width: 180,
        title: <StyledTableHeader>{t('Password')}</StyledTableHeader>,
        dataIndex: 'password',
        render: (data) => <StyledTableText>{data}</StyledTableText>,
      },
      {
        width: 210,
        title: <StyledTableHeader>{t('Allowed addresses')}</StyledTableHeader>,
        dataIndex: 'allowed_addresses',
        render: (data) => (
          <StyledTableText>{data ? data.join(', ') : ''}</StyledTableText>
        ),
      },
    ];
  }, [t]);

  return (
    <>
      <Form onFinish={onSubmit} initialValues={{ data: '' }} form={form}>
        {showTable ? (
          <Table
            pagination={false}
            bordered={true}
            columns={columns}
            dataSource={domainConfig}
          />
        ) : (
          <>
            {!!skipTestMethod ? (
              <>
                All right, now you just need to configure your DNS
              </>
            ) : (
              <>
                <Form.Item
                  name='data'
                  rules={[
                    {
                      required: true,
                      message: `${
                        variant === NewsletterTypes.sms ? 'Phone' : variant
                      } is required`,
                    },
                  ]}
                >
                  <Input placeholder={texts.placeholder} />
                </Form.Item>
                <Row justify='space-between' gutter={[10, 10]}>
                  <Col>
                    <Form.Item>
                      <Button loading={loading} type='default' htmlType='submit'>
                        {texts.buttonText}
                      </Button>
                    </Form.Item>
                  </Col>
                  {SMSBackupTestButton}
                </Row>
              </>
            )}
          </>
        )}
        <Form.Item>
          <Divider style={{ margin: 0 }} />
        </Form.Item>
        <Form.Item>
          <Button type='default' onClick={goHome}>
            {t('Go home page')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ServiceTestForm;
