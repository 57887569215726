import { SmallModal } from '../../modals';
import { Button, Switch, Col, Form, Input, Row, Divider, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useParams } from 'react-router-dom';
import UsersApi from 'libs/api/users';
import { useCallback, useEffect, useMemo, useState } from 'react';
import UIHelper from 'services/helpers/UIHelper';
import ErrorHelper from 'services/helpers/ErrorHelper';
import CompaniesApi from 'libs/api/companies';

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 28px;
`;

const CheckboxLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #000000;
`;

const StyledBtn = styled(Button)`
  width: 100%;
  height: 44px;
  font-weight: 500;
  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.4;
          color: #fff !important;
          background: #2d70be !important;
        `
      : ''}
`;

const StyledFooter = styled(Row)``;

const usersApi = new UsersApi();
const companiesApi = new CompaniesApi();

const AddOrEditCustomerModal = ({
  title = 'New access',
  onClose,
  open,
  allUsers,
  afterCreate,
  usersAllData,
}) => {
  const [inProcess, setInProcess] = useState(false);
  const [form] = Form.useForm();
  const [item, setItem] = useState({});
  const [formType, setFormType] = useState('create'); // create || assign
  const { t } = useTranslation();
  // const isChecked = Form.useWatch('is_show_credential', form);
  const isChecked = true;
  const params = useParams();
  const isNew = params.id === 'new';

  useEffect(() => {
    (async () => {
      try {
        const data = allUsers?.find((i) => i.id === +params.id);
        if (data) {
          setItem(data);
          form.setFields([
            {
              name: 'username',
              value: data.username,
            },
            {
              name: 'is_show_credential',
              // value: data.is_show_credential,
              value: true,
            },
          ]);
          form.setFields([
            {
              name: 'email',
              value: data.email,
            },
          ]);
        }
      } catch (e) {}
    })();
  }, [params, form, allUsers]);

  const onFinish = useCallback(
    async (values) => {
      try {
        setInProcess(true);
        const isEdit = item?.id || params?.id !== 'new';
        let requestData = {
          company_id: params.companyId,
          username: values.email,
          ...values,
        };
        if (isEdit) {
          await usersApi.put(item?.id || params?.id, {
            data: requestData,
          });
        } else {
          await usersApi.create({
            data: requestData,
          });
        }

        if (afterCreate) {
          afterCreate();
        }

        UIHelper.showSuccess(
          isEdit
            ? `${item.username} ${t('Successfully updated')}`
            : t('Customer successfully created'),
        );
      } catch (e) {
        UIHelper.showErrorFromAxios(e);
        form.setFields(
          ErrorHelper.getErrorsForForm(e?.response?.data?.errors || {}, {}),
        );
      } finally {
        setInProcess(false);
      }
    },
    [form, item, afterCreate, t, params],
  );

  const onAssignFinish = useCallback(
    async (values) => {
      try {
        setInProcess(true);
        await companiesApi.addUser(params.companyId, {
          data: values,
        });
        UIHelper.showSuccess(t('Successfully assigned'));
        if (afterCreate) {
          afterCreate();
        }
      } catch (e) {
        UIHelper.showErrorFromAxios(e);
        form.setFields(
          ErrorHelper.getErrorsForForm(e?.response?.data?.errors || {}, {}),
        );
      } finally {
        setInProcess(false);
      }
    },
    [form, t, afterCreate, params],
  );

  const onChangeFormType = useCallback((d) => {
    setFormType(() => (!!d ? 'assign' : 'create'));
  }, []);

  const allUsersOptions = useMemo(() => {
    const options = [];
    usersAllData?.forEach(i => {
      options.push({
        value: i.id,
        label: i.email,
      });
    });
    return options;
  }, [usersAllData]);

  return (
    <SmallModal
      open={open}
      closable={false}
      footer={
        <StyledFooter gutter={12}>
          <Col span={12}>
            <StyledBtn onClick={onClose}>{t('Cancel')}</StyledBtn>
          </Col>
          <Col span={12}>
            <StyledBtn
              onClick={() => form.submit()}
              // disabled={!selectedValue}
              type={'primary'}
              loading={inProcess}
            >
              {t('Confirm')}
            </StyledBtn>
          </Col>
        </StyledFooter>
      }
    >
      <Row justify='end' style={{ marginBottom: 20 }}>
        <Col span={24}>
          <StyledTitle style={{ marginBottom: 8 }}>
            {!!item?.username ? `${t('Edit access')}` : t('New access')}
          </StyledTitle>
        </Col>

        {isNew && (
          <>
            <Col span={24}>
              <Divider />
            </Col>
            <Col span={24}>
              <Row justify='space-between'>
                <Col>
                  <CheckboxLabel style={{ margin: 0 }}>
                    {t('Assign created access')} ?
                  </CheckboxLabel>
                </Col>
                <Col>
                  <Switch
                    onChange={onChangeFormType}
                    checkedChildren={t('Yes')}
                    unCheckedChildren={t('No')}
                    defaultChecked={false}
                  />
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
      {formType === 'create' ? (
        <Form
          form={form}
          name='add-access'
          initialValues={{
            is_show_credential: true, // TODO kakoe to pole iz becka
            // username: value,
            email: item?.email,
            password: item?.password || '',
            // count: null,
            without_credential: false,
            password_confirmation: '',
          }}
          onFinish={onFinish}
          autoComplete='off'
          layout={'vertical'}
        >
          <Row gutter={[10, 10]}>
            {isChecked && (
              <>
                <Col span={24}>
                  <Form.Item
                    name='email'
                    rules={[
                      {
                        required: isChecked,
                        message: t('Please input contacts count'),
                      },
                    ]}
                  >
                    <Input
                      style={{ width: '100%', maxWidth: 'unset' }}
                      size={'large'}
                      placeholder={t('Email')}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name='password'
                    rules={[
                      {
                        required: isChecked,
                        message: t('Please input password'),
                      },
                    ]}
                  >
                    <Input.Password
                      style={{ width: '100%', maxWidth: 'unset' }}
                      size={'large'}
                      placeholder={t('Password')}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>
      ) : (
        <>
          <Form
            form={form}
            name='assign-access'
            initialValues={{
              user_id: null,
            }}
            onFinish={onAssignFinish}
          >
            <Form.Item
              name='user_id'
              rules={[
                {
                  required: isChecked,
                  message: t('Please choose user!'),
                },
              ]}
            >
              <Select
                style={{ width: '100%', maxWidth: 'unset' }}
                size={'large'}
                placeholder={t('Access')}
                showSearch={true}
                optionFilterProp='children'
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={allUsersOptions}
              />
            </Form.Item>
          </Form>
        </>
      )}
    </SmallModal>
  );
};

export default AddOrEditCustomerModal;
