import styled from 'styled-components';
import { Button } from 'antd';

const StepButton = styled(Button)`
  background-color: #2D81E0;
  border: 1px solid #2D81E0;
  min-width: 150px;
`;

export default StepButton;
